/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../../Identity-management-individual/style.module.css";
import Spinner from "../../../components/spinner";
import Receipt from "../../ReceiptPymtRef/receipt";

const user = JSON.parse(localStorage.getItem("user_info"));
const ViewBill = () => {
	const [data, setData] = useState({});
	const [invoice, setInvoice] = useState("");
	const [loading, setLoading] = useState(false);
	let { state } = useLocation();

	// //if in editing mode, fill details
	// useEffect(() => {
	// 	if (historyState?.edit || historyState?.view) {

	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const fetchInvoice = async (billNumber) => {
		setLoading(true);
		await axios
			.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}`)
			.then((res) => {
				setInvoice(res?.data);
			})
			.catch((error) => console.log({ error }))
			.finally(() => setLoading(false));
	};

	const printInvoiceFile = (e) => {
		e.preventDefault();

		if (!!invoice) {
			const w = window.open();
			w.document.write(invoice);
			w.document.close();
			w.onload = () => {
				w.focus();
				w.print();
			};

			w.onafterprint = () => {
				w.close();
			};
		} else {
			return;
		}
	};

	const fetchCurrentBill = useCallback(async () => {
		const res = await axios
			.get(`billing/${state.coy}/${state.userId}`)
			.then((res) => (res.data || {}).data?.result);

		if (res?.BillNumber) {
			fetchInvoice(res?.BillNumber);
		}
		setData(res);
	}, [state.userId, state.coy]);

	useEffect(() => {
		fetchCurrentBill();
	}, [fetchCurrentBill]);
	return (
		<>
			<form onSubmit={printInvoiceFile}>
				<header>
					<h2>Taxpayer bill</h2>
				</header>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							Esbn
						</label>
						<input
							type="text"
							id="ownersesbn"
							disabled
							value={data?.OwnersESBN ?? ""}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Name
						</label>
						<input
							type="text"
							id="ownersname"
							disabled
							value={data?.OwnersName ?? ""}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Address
						</label>
						<input
							type="text"
							id="residentialaddress"
							disabled
							value={data?.OwnersAddr ?? ""}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							id="phonenumber"
							disabled
							value={data?.OwnersPhone ?? ""}
						/>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<br />
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							id="email"
							disabled
							value={data?.OwnerEmail ?? ""}
						/>
					</div>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							id="discount"
							disabled
							value={data?.Discount ?? ""}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<br />
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							id="outstanding"
							disabled
							value={data?.Outstanding ?? ""}
						/>
					</div>
				</div>

				{data?.TaxPayerBillsDetails?.length &&
					data?.TaxPayerBillsDetails?.map((billDetail, index) => (
						<React.Fragment key={`billDetail${index}`}>
							<br />
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="revenuedescription"
									>
										Property ID
									</label>
									<input
										type="text"
										name="propertyid"
										id="propertyid"
										disabled
										value={billDetail?.PropertyId ?? ""}
									/>
								</div>
							</div>
							<br />
							<h3>Address</h3>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseNo"
									>
										No
									</label>
									<input
										type="text"
										name="houseNo"
										id="houseNo"
										disabled
										value={billDetail?.HouseNo ?? ""}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="HouseStr"
									>
										Street
									</label>
									<input
										type="text"
										name="HouseStr"
										id="HouseStr"
										disabled
										value={billDetail?.HouseStreet ?? ""}
									/>
								</div>
							</div>

							<br />
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseArea"
									>
										Area
									</label>
									<select
										id="houseArea"
										className={styles.input__long}
										name="houseArea"
										value={billDetail?.HouseArea ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseArea ?? ""}
										</option>
									</select>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseLGA"
									>
										LGA
									</label>
									<select
										id="houseLGA"
										className={styles.input__long}
										name="houseLGA"
										value={billDetail?.HouseLGA ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseLGA ?? ""}
										</option>
									</select>
								</div>
							</div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="HouseZone"
									>
										Zone
									</label>
									<select
										id="HouseZone"
										className={styles.input__long}
										name="HouseZone"
										value={billDetail?.HouseZone ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseZone ?? ""}
										</option>
									</select>
								</div>
							</div>

							<div className={styles.double__inputs}>
								<div>
									<br />

									<label
										className={styles.input__label}
										htmlFor="revenuedescription"
									>
										Description
									</label>
									<input
										type="text"
										name="revenuedescription"
										id="revenuedescription"
										disabled
										value={
											billDetail?.RevenueDescription ?? ""
										}
									/>
								</div>
							</div>

							<br />
							<br />
							<div className={styles.select__wrapper}>
								<div>
									<label htmlFor="billtype">
										Bill Type{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="billtype"
										className={styles.input__medium}
										name="billtype"
										value={billDetail.billtype}
										disabled
									>
										<option>
											{billDetail?.BillType ?? ""}
										</option>
									</select>
								</div>
							</div>
							<div className={styles.select__wrapper}>
								<div>
									<label htmlFor="profile">
										Category{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="profile"
										className={styles.input__medium}
										name="profile"
										value={billDetail.Profile}
										disabled
									>
										<option>
											{billDetail?.Profile ?? ""}
										</option>
									</select>
								</div>
								<div>
									<label htmlFor="profilecategory">
										Sub-Category{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="profilecategory"
										className={styles.input__medium}
										name="profilecategory"
										value={billDetail?.ProfileCategory}
										disabled
									>
										<option>
											{billDetail?.ProfileCategory ?? ""}
										</option>
									</select>
								</div>
							</div>
							{billDetail?.LandMeasurement && (
								<>
									<div className={styles.double__inputs}>
										<div>
											<label
												className={styles.input__label}
												htmlFor="landMeasurement"
											>
												Land Measurement{" "}
												<span style={{ color: "red" }}>
													*
												</span>
											</label>
											<input
												type="text"
												name="landMeasurement"
												id="landMeasurement"
												disabled
												value={
													billDetail?.LandMeasurement ??
													""
												}
											/>
										</div>
									</div>
									<br />
								</>
							)}
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="amount"
									>
										Amount{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="amount"
										id="amount"
										disabled
										value={billDetail?.Amount ?? ""}
									/>
								</div>
							</div>
						</React.Fragment>
					))}
				<br />
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.TotalAmtBilled}
					</h4>
				</div>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.GrandTotal}
					</h4>
				</div>

				<hr className={styles.divider} />
				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Download Invoice
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</>
	);
};

export default ViewBill;
