import React from "react";
import axios from "axios";
import Table from "../../../components/Table4";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import { useState, useEffect, createRef, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/arrowLeft.svg";
import { formatAmount } from "../../../utils";
import Alert from "../../../components/Alert";

const Auth = () => {
	const accttype = JSON.parse(localStorage.getItem("user_info")).account_type;
	console.log("accttype", accttype);
	const corpESBN = JSON.parse(localStorage.getItem("user_info")).anssid;
	console.log("corpESBN", corpESBN);
	// const userInfo = JSON.parse(localStorage.getItem("user_info"));
	// console.log("userInfoCheck", userInfo);

	let userId;

	const history = useHistory();
	const location = useLocation();
	console.log("location", location);
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	// eslint-disable-next-line
	const [StaffName, setStaffName] = useState("");
	// eslint-disable-next-line
	const [ESBN, setESBN] = useState("");
	// const [uploading, setUploading] = useState(false);
	// eslint-disable-next-line
	const [companyName, setCompanyName] = useState("");
	// eslint-disable-next-line
	const [BillNumber, setBillNumber] = useState("");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	// eslint-disable-next-line
	const [annualTax, setAnnualTax] = useState(null);
	// eslint-disable-next-line
	const [PAYEmainBillNumber, setPAYEmainBillNumber] = useState("");
	// eslint-disable-next-line
	const [onApprove, setonApprove] = useState();
	// eslint-disable-next-line
	const [showingDownloadProcessedBtn, setShowingDownloadProcessedBtn] =
		useState(false);
	// eslint-disable-next-line
	const [paymentStatus, setPaymentStatus] = useState("");
	// eslint-disable-next-line
	const [taxYear, setTaxYear] = useState("");
	// eslint-disable-next-line
	const [revokeModalStatus, setRevokeModalStatus] = useState({
		revoking: false,
		showing: false,
		data: null
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	console.log("authhhhhhhhhhh");

	const filterRef = createRef();
	const currentDate = new Date();
	// eslint-disable-next-line
	const currentYear = currentDate.getFullYear();
	const userIdRef = useRef();

	// if (
	// 	JSON.parse(localStorage.getItem("user_info")).account_type ===
	// 	"corporate"
	// ) {
	// 	userId = JSON.parse(localStorage.getItem("user_info")).anssid;
	// } else {
	// 	userId = location.state.CoyESBN;
	// }

	userId = JSON.parse(localStorage.getItem("user_info")).email;
	// const userIdRef = useRef(userId);

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			console.log("getAssessmentRecords [userid] --> ", userId);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			// let query = `page=${currentPage}&pageSize=20&`;
			// if (StaffName) {
			// 	query += `StaffName=${StaffName}&`;
			// }

			try {
				const accessToken = localStorage.getItem("access_token");
				let { fiscalYear } = location.state;

				let { data, annualtax, total } = await axios
					.get(
						`/dareports/tmspayedisplayDetails/${userIdRef.current}/${fiscalYear}/ALL`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });
				console.log("API Response:", data);

				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				setTotal(total);
				setAnnualTax(annualtax);
			} catch (err) {
				console.error(err);
			} finally {
				setFinished("1");
				// setLoading(false);
			}
		};

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("user_info")).account_type ===
			"corporate"
		) {
			userIdRef.current = JSON.parse(
				localStorage.getItem("user_info")
			).anssid;
		} else {
			userIdRef.current = location.state.CoyESBN;
		}
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line
	}, [!!alert.showing]);

	const revoke = async () => {
		setRevokeModalStatus((revokeStatus) => ({
			...revokeStatus,
			revoking: true
		}));

		try {
			const { msg } = await axios
				.put("genprocess", {
					billCode: location.state.BillNumber,
					processUpd: "3",
					MDA_Flg: "03",
					coy: "03",
					userid: userId,
					menu: "paye"
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: "Bill has been successfully revoked"
			});

			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: msg // error.response.data?.msg
			});

			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const approve = async () => {
		setRevokeModalStatus((revokeStatus) => ({
			...revokeStatus,
			revoking: true
		}));

		try {
			const { msg } = await axios
				.put("genprocess", {
					billCode: location.state.BillNumber,
					processUpd: "2",
					MDA_Flg: "03",
					coy: "03",
					userid: userId,
					menu: "paye"
				})
				.then((res) => res.data);

			// setAlert({
			// 	...alert,
			// 	showing: true,
			// 	type: "error",
			// 	message: "Bill has successfully been Approved"
			// });

			if (data.status === 200) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: "Bill has successfully been Approved"
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: msg // error.response.data?.msg
				});
			}

			// if (data.status === 200) {
			// 	setAlert({
			// 		...alert,
			// 		showing: true,
			// 		type: "success",
			// 		message: "Upload Successful! Go back to see Paye Details"
			// 	});
			// }

			getAssessmentRecords()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	// const clearFilters = () => {
	// 	setESBN("");
	// 	setCompanyName("");
	// 	setBillNumber("");
	// 	setPaymentStatus("");

	// 	filterRef.current.classList.toggle(styles1.no__display);
	// 	// setFiltersCleared(true);
	// };
	// const downloadFormat = () => {
	// 	const element = document.createElement("a");
	// 	element.href = PAYESchedule;
	// 	element.download = "PAYE format";
	// 	element.click();
	// };

	const headers = [
		// [`ESBN`, "CoyESBN"],
		// ["Company Name", "CoyName"],
		["Staff Name ", "StaffName"],
		["Total Annual Income Per Annum ", "AnnualPay", formatAmount],
		["Total Income Per Month ", "MthPay", formatAmount],
		["CRA N200,000.00 + 20% of 3 ", "CRA", formatAmount],
		["Pension Contribution Per Annum 8% of 3  ", "Pension", formatAmount],
		[
			"NHIS Contribution Per Annum 2.5% of 3 or less  ",
			"NHIS",
			formatAmount
		],
		["NHF Contribution Per Annum 2.5% of 3 or less ", "NHF", formatAmount],
		["Life Assurance Contribution Per Annum ", "Life", formatAmount],
		["Total Relief Sum of 5 to 9 ", "TotRelief", formatAmount],
		["Taxable Income 3 - 10 ", "TaxableIncome", formatAmount],
		["Tax Due Per Annum ", "AnnualTaxDue", formatAmount],
		["Tax Due Per Month ", "MthTaxDue", formatAmount],
		["Development Levy N500.00 per Annum ", "DevLevy", formatAmount]
	];

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div>
				<button
					className={`${styles.review_header_btn} ${styles.customBackButton}`}
					onClick={() => history.goBack(-1)}
				>
					<img
						src={backArrow}
						alt="back arrow"
						className={styles.backArrow}
					/>{" "}
					<span className={styles.backText}> Go to Record</span>
				</button>
				<div className={styles.auth_btn}>
					<button
						className={["primary__btn", styles.approve__btn].join(
							" "
						)}
						onClick={approve}
					>
						Approve
					</button>
					<button
						className={styles.revoke__btn}
						// className={["secondary__btn", styles.revoke__btn].join(" ")}
						onClick={revoke}
					>
						Revoke
					</button>
				</div>
			</div>
			<div className={styles.review_header}>
				<div>
					<h2>PAYE Bill Number: {location.state.BillNumber}</h2>
					<h2>
						AMOUNT DUE: &#8358;{" "}
						{formatAmount(location.state.totAmtCharged)}
					</h2>
				</div>
			</div>
			<div style={{ margin: "0px", marginTop: "30px" }}>
				<div className={styles1.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table
							headers={headers}
							data={data}
							full
							pageSize={20}
							total={total}
							onPageChanged={getAssessmentRecords}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default Auth;
