import React from "react";
import MoniePoint from "./moniepoint";
import style from "./style.module.css";

const MoniePointPage = () => {
	return (
		<div className={[style.row]}>
			<MoniePoint />
		</div>
	);
};

export default MoniePointPage;
