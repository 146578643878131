import React from "react";
import style from "./style.module.css";
import InterSwitcheb from "./interswitchpay";

const Interswitchpage = () => {
	return (
		<div className={[style.row]}>
			<InterSwitcheb />
		</div>
	);
};

export default Interswitchpage;
