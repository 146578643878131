import React from "react";
import Etransacpayment from "./etransac";
import style from "./style.module.css";

const Etranscpage = () => {
	return (
		<div className={[style.row]}>
			<Etransacpayment />
		</div>
	);
};

export default Etranscpage;
