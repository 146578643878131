import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
	exportExcel,
	formatAmount,
	formatDate,
	percentIncrease,
	remapObj
} from "../../utils";

import redBill from "../../assets/SpecialDashboard/red.svg";
import blueBill from "../../assets/SpecialDashboard/blue.svg";
import greenBill from "../../assets/SpecialDashboard/green.svg";
import purpleBill from "../../assets/SpecialDashboard/purple.svg";

import greenProfile from "../../assets/SpecialDashboard/greenProfile.svg";
import blueProfile from "../../assets/SpecialDashboard/blueProfile.svg";
import redProfile from "../../assets/SpecialDashboard/redProfile.svg";

import Table from "../../components/Table";
import Alert from "../../components/Alert";

import styles from "./style.module.css";
import chartStyles from "../summary/style.module.css";
//femi
import * as XLSX from "xlsx";
import { BounceLoader } from "react-spinners";

//femi

const coy_ANSSID = process.env.REACT_APP_ANSSID;

const getCurrentQuarter = () => {
	const today = new Date();
	const currentMonth = today.getMonth() + 1;
	if (currentMonth <= 3) {
		return 1;
	} else if (currentMonth <= 6) {
		return 2;
	} else if (currentMonth <= 9) {
		return 3;
	} else {
		return 4;
	}
};

const Dashboard = () => {
	const monthlyLabels = [
		"JAN",
		"FEB",
		"MAR",
		"APR",
		"MAY",
		"JUN",
		"JUL",
		"AUG",
		"SEPT",
		"OCT",
		"NOV",
		"DEC"
	];
	const dailyLabels = ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"];
	const quarterlyLabels = ["Q1", "Q2", "Q3", "Q4"];
	const yearlyLabels = [
		new Date().getFullYear(),
		new Date().getFullYear() - 1,
		new Date().getFullYear() - 2,
		new Date().getFullYear() - 3
	];

	const [todaysRevenue, setTodaysRevenue] = useState(0);
	const [yesterdaysRevenue, setYesterdaysRevenue] = useState(0);
	const [currWeekRevenue, setCurrWeekRevenue] = useState(0);
	const [currWeekRevenueBreakdown, setCurrWeekRevenueBreakdown] = useState(
		[]
	);
	const [prevWeekRevenue, setPrevWeekRevenue] = useState(0);
	const [currMonthsRevenue, setCurrMonthsRevenue] = useState(0);
	const [prevMonthsRevenue, setPrevMonthsRevenue] = useState(0);
	const [revenuesByMonth, setRevenuesByMonth] = useState([]);
	const [currMonthRevBreakdown, setCurrMonthRevBreakdown] = useState([]);
	const [prevMonthRevBreakdown, setPrevMonthRevBreakdown] = useState([]);
	const [quarterlyRevenue, setQuarterlyRevenue] = useState([]);
	const [currYearsRevenue, setCurrYearsRevenue] = useState(0);
	const [prevYearsRevenue, setPrevYearsRevenue] = useState(0);
	const [prevYearRevenueByMonth, setPrevYearRevenueByMonth] = useState([]);
	const [prevYearRevenueByQtr, setPrevYearRevenueByQtr] = useState([]);
	const [revenuesByYear, setRevenuesByYear] = useState([]);
	const [anssidBreakdown, setAnssidBreakdown] = useState({});
	const [todaysAnssid, setTodaysAnssid] = useState(0);
	const [yesterdaysAnssid, setYesterdaysAnssid] = useState(0);

	// Femi - breakdown
	const [loadingBrkDown, setLoadingBrkDown] = useState(0);

	const [payGatewayTodayRevenue, setPayGatewayTodayRevenue] = useState([]);
	const [payGatewayWeekRevenue, setPayGatewayWeekRevenue] = useState([]);
	const [payGatewayMonthRevenue, setPayGatewayMonthRevenue] = useState([]);
	const [payGatewayYearRevenue, setPayGatewayYearRevenue] = useState([]);
	// Femi - breakdown

	const [targetMonth, setTargetMonth] = useState("");
	const [loadingTargetData, setLoadingTargetData] = useState(false);
	const [tableData, setTableData] = useState(null);

	const [MDAs, setMDAs] = useState([]);
	const [weeklyBreakdownMDA, setWeeklyBreakdownMDA] = useState("");
	const [loadingWeeklyGraphicalData, setLoadingWeeklyGraphicalData] =
		useState(false);

	const [yearBreakdownMDA, setYearBreakdownMDA] = useState("");
	const [loadingYearGraphicalData, setLoadingYearGraphicalData] =
		useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getVariant = (valuesArray) =>
		`${percentIncrease(valuesArray[0], valuesArray[1])} %`;

	const targetTableHeaders = [
		["S/N", "SN"],
		["MDA", "MDADescription"],
		["Monthly Target (₦)", "MonthlyTarget", formatAmount],
		["Current Month (₦)", "current_month_total", formatAmount],
		["Previous Month (₦)", "previous_month_total", formatAmount],
		["MTM Variance", "mtmVariance"],
		["RTB Variance", "rtbVariance"]
	];

	const getSpecialReports = async () => {
		setTargetMonth("");
		setLoadingTargetData(true);
		try {
			const result = await axios
				.get("/admin/reports/specialreports")
				.then((res) => (res.data || {}).data);

			setPayGatewayTodayRevenue(result.payGatewayTodayRevenue);
			setPayGatewayWeekRevenue(result.payGatewayWeekRevenue);
			setPayGatewayMonthRevenue(result.payGatewayMonthRevenue);
			setPayGatewayYearRevenue(result.payGatewayYearRevenue);

			setTodaysRevenue(result.todaysRevenue);
			setYesterdaysRevenue(result.yesterdaysRevenue);
			setCurrWeekRevenue(result.currWeek);
			setCurrWeekRevenueBreakdown(
				(() => {
					let weekData = {};
					result?.weekRevenueBrkDn?.forEach((each) => {
						if (each.dayz === "Sun") {
							return (weekData.SUN = parseFloat(each.Amount));
						} else if (each.dayz === "Mon") {
							return (weekData.MON = parseFloat(each.Amount));
						} else if (each.dayz === "Tue") {
							return (weekData.TUE = parseFloat(each.Amount));
						} else if (each.dayz === "Wed") {
							return (weekData.WED = parseFloat(each.Amount));
						} else if (each.dayz === "Thu") {
							return (weekData.THUR = parseFloat(each.Amount));
						} else if (each.dayz === "Fri") {
							return (weekData.FRI = parseFloat(each.Amount));
						} else if (each.dayz === "Sat") {
							return (weekData.SAT = parseFloat(each.Amount));
						}
						return null;
					});

					return dailyLabels.map(
						(day) => parseFloat(weekData[day]) || 0
					);
				})()
			);
			setPrevWeekRevenue(result.prevWeekRevenue);
			setCurrMonthsRevenue(result.currMonthsRevenue);
			setPrevMonthsRevenue(result.prevMonthsRevenue);
			setRevenuesByMonth(
				monthlyLabels.map(
					(month) =>
						result.yearsRevenueMthBkDn.find((m) => m.mth === month)
							?.Amount || 0
				)
			);
			setCurrMonthRevBreakdown(result.MthRevenueHeadBrkDn);
			setPrevMonthRevBreakdown(result.PrevMthRevenueHeadBrkDn);
			setQuarterlyRevenue(() => {
				let qtrData = {};
				result.qtrReport.map((quarter) => {
					if (quarter.qtrNo === 1) {
						return (qtrData.Q1 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 2) {
						return (qtrData.Q2 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 3) {
						return (qtrData.Q3 = parseFloat(quarter.Amount));
					} else if (quarter.qtrNo === 4) {
						return (qtrData.Q4 = parseFloat(quarter.Amount));
					}
					return null;
				});

				return quarterlyLabels.map(
					(qtr) => parseFloat(qtrData[qtr]) || 0
				);
			});
			setCurrYearsRevenue(result.currYearsRevenue);
			setPrevYearsRevenue(result.prevYearsRevenue);
			setRevenuesByYear([
				result.currYearsRevenue,
				result.prevYearsRevenue,
				result.twoYearsAgoRevenue,
				result.threeYearsAgoRevenue
			]);
			setPrevYearRevenueByMonth(
				result.prevYearsRevenueMth.map((month) => month.Amount)
			);
			setPrevYearRevenueByQtr(
				result.prevYearsRevenueQtr.map((qtr) => qtr.Amount)
			);
			setAnssidBreakdown(() => {
				const obj = {
					individualCount: 0,
					corporateCount: 0,
					totalCount: 0
				};

				result.anssidNow.forEach((anssid) => {
					if (anssid.Account_type === "individual") {
						obj.totalCount += anssid.kount;
						return (obj.individualCount = anssid.kount);
					} else {
						obj.totalCount += anssid.kount;
						return (obj.corporateCount = anssid.kount);
					}
				});

				return obj;
			});
			setTodaysAnssid(result.todaysAnssid);
			setYesterdaysAnssid(result.yesterdaysAnssid);

			const modifiedBudget2RevenuePerformance =
				result?.Budget2RevenuePerformance.map((each, i) => ({
					...each,
					SN: i + 1,
					mtmVariance: getVariant([
						`${each.previous_month_total}`,
						`${each.current_month_total}`
					]),
					rtbVariance: getVariant([
						`${each.MonthlyTarget}`,
						`${each.current_month_total}`
					])
				}));

			setTableData(modifiedBudget2RevenuePerformance);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingTargetData(false);
		}
	};

	const getWeeklyMDAGraphicalData = async (mdaCode) => {
		try {
			setLoadingWeeklyGraphicalData(true);

			const data = await axios.get(
				`/reports/specialreportsbrkdn/totcollect/${mdaCode}/week`
			);

			setCurrWeekRevenueBreakdown(
				(() => {
					let weekData = {};
					data?.data?.data?.forEach((each) => {
						if (each.dayz === "Sun") {
							return (weekData.SUN = parseFloat(each.Amount));
						} else if (each.dayz === "Mon") {
							return (weekData.MON = parseFloat(each.Amount));
						} else if (each.dayz === "Tue") {
							return (weekData.TUE = parseFloat(each.Amount));
						} else if (each.dayz === "Wed") {
							return (weekData.WED = parseFloat(each.Amount));
						} else if (each.dayz === "Thu") {
							return (weekData.THUR = parseFloat(each.Amount));
						} else if (each.dayz === "Fri") {
							return (weekData.FRI = parseFloat(each.Amount));
						} else if (each.dayz === "Sat") {
							return (weekData.SAT = parseFloat(each.Amount));
						}
						return null;
					});

					return dailyLabels.map(
						(day) => parseFloat(weekData[day]) || 0
					);
				})()
			);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingWeeklyGraphicalData(false);
		}
	};

	useEffect(() => {
		if (weeklyBreakdownMDA) getWeeklyMDAGraphicalData(weeklyBreakdownMDA);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weeklyBreakdownMDA]);

	const getYearlyMDAGraphicalData = async (mdaCode) => {
		try {
			setLoadingYearGraphicalData(true);

			const data = await axios.get(
				`/reports/specialreportsbrkdn/totcollectyear/${mdaCode}/year`
			);

			setRevenuesByMonth(
				monthlyLabels.map(
					(month) =>
						data.data.data?.find((m) => m.mth === month)?.Amount ||
						0
				)
			);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingYearGraphicalData(false);
		}
	};

	useEffect(() => {
		getYearlyMDAGraphicalData(yearBreakdownMDA);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [yearBreakdownMDA]);

	useEffect(() => {
		getSpecialReports();

		//refresh every 5 mins
		const interval = setInterval(() => getSpecialReports(), 300000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const options = {
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					}
					// display: false // this will hide vertical lines
				}
			],
			yAxes: [
				{
					gridLines: {
						display: false,
						drawOnChartArea: false,
						drawTicks: false
					},
					display: false // this will hide vertical lines
				}
			]
		}
	};

	const data = (values, labels, header, colors) => {
		return {
			labels: labels,
			datasets: [
				{
					label: header,
					data: values,
					fill: true,
					backgroundColor: colors,
					// borderColor: "#6204DC"
					// color: '#0242C2',
					borderWidth: 1
				}
			]
		};
	};

	const handleItemBreakDownClick = async (agentCode, agentDescr, reptype) => {
		try {
			// console.log(`handleItemBreakDownClick  | agentCode= ${agentCode}; agentDescr => ${agentDescr}; reptype => ${reptype}`);
			setLoadingBrkDown(`${agentCode}${reptype}`);

			const currentDate = new Date();

			// Get individual date and time components
			const day = currentDate.getDate().toString().padStart(2, "0");
			const month = (currentDate.getMonth() + 1)
				.toString()
				.padStart(2, "0"); // Months are zero-based
			const year = currentDate.getFullYear();
			const hours = currentDate.getHours().toString().padStart(2, "0");
			const minutes = currentDate
				.getMinutes()
				.toString()
				.padStart(2, "0");
			const seconds = currentDate
				.getSeconds()
				.toString()
				.padStart(2, "0");

			// Concatenate components in the desired format
			const formattedDateTime = `${day}${month}${year}_${hours}${minutes}${seconds}`;

			const info = await axios
				.get(
					`/reports/specialreportsbrkdn/agency/${agentCode}/${reptype}`
				)
				.then((res) => (res.data || {}).data);

			if (info) {
				// const formattedData = data.map(row => ({
				// 	...row,
				// 	Amount: parseFloat(row.Amount.replace(/,/g, '')), // Convert to number
				//   }));

				const ws = XLSX.utils.json_to_sheet(info);

				// Rename columns
				ws["A1"].v = "Revenue Code";
				ws["B1"].v = "Revenue Name";
				ws["C1"].v = "Transaction Count";
				ws["D1"].v = "Total Amount";

				// Convert 'Total Amount' column to numbers for all rows
				for (let i = 2; i <= info.length + 1; i++) {
					ws[`D${i}`].t = "n"; // Set the data type to number for the 'Total Amount' column
				}

				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Dashboard_Breakdown");
				XLSX.writeFile(wb, `${agentDescr}_${formattedDateTime}.xlsx`);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingBrkDown(0);
		}
	};
	const handleItemBreakDownClick2 = async (agentDescr, reptype) => {
		try {
			setLoadingBrkDown(agentDescr);

			const currentDate = new Date();

			// Get individual date and time components
			const day = currentDate.getDate().toString().padStart(2, "0");
			const month = (currentDate.getMonth() + 1)
				.toString()
				.padStart(2, "0"); // Months are zero-based
			const year = currentDate.getFullYear();
			const hours = currentDate.getHours().toString().padStart(2, "0");
			const minutes = currentDate
				.getMinutes()
				.toString()
				.padStart(2, "0");
			const seconds = currentDate
				.getSeconds()
				.toString()
				.padStart(2, "0");

			// Concatenate components in the desired format
			const formattedDateTime = `${day}${month}${year}_${hours}${minutes}${seconds}`;

			const info = await axios
				.get(`/reports/specialreportsbulkbrkdn/bulk/${reptype}`)
				.then((res) => (res.data || {}).data);

			if (info) {
				// const formattedData = data.map(row => ({
				// 	...row,
				// 	Amount: parseFloat(row.Amount.replace(/,/g, '')), // Convert to number
				//   }));

				const ws = XLSX.utils.json_to_sheet(info);

				// Rename columns
				ws["A1"].v = "Revenue Code";
				ws["B1"].v = "Revenue Name";
				ws["C1"].v = "Transaction Count";
				ws["D1"].v = "Total Amount";

				// Convert 'Total Amount' column to numbers for all rows
				for (let i = 2; i <= info.length + 1; i++) {
					ws[`D${i}`].t = "n"; // Set the data type to number for the 'Total Amount' column
				}

				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Dashboard_Breakdown");
				XLSX.writeFile(wb, `${agentDescr}_${formattedDateTime}.xlsx`);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingBrkDown(0);
		}
	};
	const handleItemBreakDownClick3 = async (trpname, paygate, reptype) => {
		try {
			setLoadingBrkDown(trpname);

			const currentDate = new Date();

			// Get individual date and time components
			const day = currentDate.getDate().toString().padStart(2, "0");
			const month = (currentDate.getMonth() + 1)
				.toString()
				.padStart(2, "0"); // Months are zero-based
			const year = currentDate.getFullYear();
			const hours = currentDate.getHours().toString().padStart(2, "0");
			const minutes = currentDate
				.getMinutes()
				.toString()
				.padStart(2, "0");
			const seconds = currentDate
				.getSeconds()
				.toString()
				.padStart(2, "0");

			// Concatenate components in the desired format
			const formattedDateTime = `${day}${month}${year}_${hours}${minutes}${seconds}`;

			const info = await axios
				.get(
					`/reports/specialreportspaygatesbrkdn/paygate/${paygate}/${reptype}`
				)
				.then((res) => (res.data || {}).data);

			if (info) {
				const ws = XLSX.utils.json_to_sheet(info);

				// Rename columns
				ws["A1"].v = "Revenue Code";
				ws["B1"].v = "Revenue Name";
				ws["C1"].v = "Transaction Count";
				ws["D1"].v = "Total Amount";

				// Convert 'Total Amount' column to numbers for all rows
				for (let i = 2; i <= info.length + 1; i++) {
					ws[`D${i}`].t = "n"; // Set the data type to number for the 'Total Amount' column
				}

				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Dashboard_Breakdown");
				XLSX.writeFile(wb, `${trpname}_${formattedDateTime}.xlsx`);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingBrkDown(0);
		}
	};

	const downloadMonthlyTargetAndOutcome = () => {
		exportExcel(
			remapObj(tableData, targetTableHeaders),
			targetTableHeaders,
			`Monthly Target and Outcome Table (${formatDate(new Date())}).xlsx`
		);
	};

	//fetch all MDAs
	useEffect(() => {
		const getAllMDAs = async () => {
			try {
				const mdas = await axios
					.get("/settings/allmdas")
					.then((res) => (res.data || {}).data);

				setMDAs(mdas);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			}
		};

		getAllMDAs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		const getMonthlyTarget = async () => {
			setLoadingTargetData(true);
			try {
				let target = await axios
					.get(
						`/admin/reports/metricstable/${new Date().getFullYear()}-${targetMonth}-01`
					)
					.then((res) => (res.data || {}).data);

				target = target.map((each, i) => ({
					...each,
					SN: i + 1,
					current_month_total: each.Current_mth,
					mtmVariance: each.MTM_Variance,
					rtbVariance: each.RTB_Variance
				}));

				setTableData(target);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoadingTargetData(false);
			}
		};

		if (targetMonth) getMonthlyTarget();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetMonth]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<section
				className={styles.top__bar}
				title="Click to download breakdown in excel"
			>
				<div
					className={styles.top__card}
					onClick={() => {
						handleItemBreakDownClick2("Today", "today");
					}}
				>
					<img src={blueBill} alt="blue bill" />
					<div className={styles.numbers}>
						{loadingBrkDown === "Today" && (
							<BounceLoader color="#36D7B7" size={20} />
						)}
						<p>Revenue Generated</p>
						<p>{formatAmount(todaysRevenue)}</p>
					</div>
					<p>
						Today{" "}
						<span
							style={{
								color:
									percentIncrease(
										yesterdaysRevenue,
										todaysRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(yesterdaysRevenue, todaysRevenue)}%
						</span>
					</p>
				</div>
				<div
					className={styles.top__card}
					onClick={() => {
						handleItemBreakDownClick2("Week", "week");
					}}
				>
					<img src={redBill} alt="red bill" />
					<div className={styles.numbers}>
						{loadingBrkDown === "Week" && (
							<BounceLoader color="#36D7B7" size={20} />
						)}
						<p>Revenue Generated</p>
						<p>{formatAmount(currWeekRevenue)}</p>
					</div>
					<p>
						WTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevWeekRevenue,
										currWeekRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(prevWeekRevenue, currWeekRevenue)}%
						</span>
					</p>
				</div>
				<div
					className={styles.top__card}
					onClick={() => {
						handleItemBreakDownClick2("Month", "curmth");
					}}
				>
					<img src={greenBill} alt="green bill" />
					<div className={styles.numbers}>
						{loadingBrkDown === "Month" && (
							<BounceLoader color="#36D7B7" size={20} />
						)}
						<p>Revenue Generated</p>
						<p>{formatAmount(currMonthsRevenue)}</p>
					</div>
					<p>
						MTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevMonthsRevenue,
										currMonthsRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(
								prevMonthsRevenue,
								currMonthsRevenue
							)}
							%
						</span>
					</p>
				</div>
				<div
					className={styles.top__card}
					onClick={() => {
						handleItemBreakDownClick2("Year", "year");
					}}
				>
					<img src={purpleBill} alt="purple bill" />
					<div className={styles.numbers}>
						{loadingBrkDown === "Year" && (
							<BounceLoader color="#36D7B7" size={20} />
						)}
						<p>Revenue Generated</p>
						<p>{formatAmount(currYearsRevenue)}</p>
					</div>
					<p>
						YTD{" "}
						<span
							style={{
								color:
									percentIncrease(
										prevYearsRevenue,
										currYearsRevenue
									) < 0
										? "red"
										: "#2F9986"
							}}
						>
							{percentIncrease(
								prevYearsRevenue,
								currYearsRevenue
							)}
							%
						</span>
					</p>
				</div>
			</section>
			{!loadingTargetData ? (
				<>
					<div className={styles.target__filter}>
						<b className="target__year">
							{new Date().getFullYear()}
						</b>
						<select
							value={targetMonth}
							onChange={(e) => setTargetMonth(e.target.value)}
						>
							<option value="">Select Month</option>
							<option value="02">January</option>
							<option value="03">February</option>
							<option value="04">March</option>
							<option value="05">April</option>
							<option value="06">May</option>
							<option value="07">June</option>
							<option value="08">July</option>
							<option value="09">August</option>
							<option value="10">September</option>
							<option value="11">October</option>
							<option value="12">November</option>
							<option value="13">December</option>
						</select>
						<button
							className="primary__btn"
							style={{
								padding: "0 20px"
							}}
							onClick={() => downloadMonthlyTargetAndOutcome()}
						>
							Download Table
						</button>
					</div>
					<div className={styles.target__table}>
						<Table
							headers={targetTableHeaders}
							data={tableData}
							full
							pageSize={20}
							total={tableData?.length}
						/>
					</div>
				</>
			) : (
				<p style={{ textAlign: "center", marginTop: "20px" }}>
					Loading...
				</p>
			)}
			<div
				className={chartStyles.stats}
				style={{ marginTop: "36px", marginBottom: "36px" }}
			>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<div className={styles.graph__header}>
							<p>Total Collections - Daily</p>
							<select
								value={weeklyBreakdownMDA}
								onChange={(e) =>
									setWeeklyBreakdownMDA(e.target.value)
								}
							>
								<option value="">--Select MDA--</option>
								{MDAs?.map((mda) => (
									<option key={mda.Code} value={mda.Code}>
										{mda.Description}
									</option>
								))}
							</select>
						</div>
						<p>
							&#8358;{" "}
							{!weeklyBreakdownMDA
								? formatAmount(todaysRevenue)
								: formatAmount(
										currWeekRevenueBreakdown[
											new Date().getDay()
										]
									) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											yesterdaysRevenue,
											todaysRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									yesterdaysRevenue,
									todaysRevenue
								)}
								%
							</span>
						</p>
						<div>
							{loadingWeeklyGraphicalData ? (
								<p style={{ textAlign: "center" }}>
									Loading...
								</p>
							) : (
								<Line
									data={data(
										currWeekRevenueBreakdown,
										dailyLabels,
										"",
										["#6204DC"]
									)}
									options={options}
								/>
							)}
						</div>
					</div>
					<div className={chartStyles.stat}>
						<div className={styles.graph__header}>
							<p>
								Total Collections - Monthly (
								{new Date().getFullYear()})
							</p>
							<select
								value={yearBreakdownMDA}
								onChange={(e) =>
									setYearBreakdownMDA(e.target.value)
								}
							>
								<option value="">--Select MDA--</option>
								{MDAs?.map((mda) => (
									<option key={mda.Code} value={mda.Code}>
										{mda.Description}
									</option>
								))}
							</select>
						</div>
						<p>
							&#8358;{" "}
							{!yearBreakdownMDA
								? formatAmount(currMonthsRevenue)
								: formatAmount(
										revenuesByMonth[new Date().getMonth()]
									) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevMonthsRevenue,
											currMonthsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevMonthsRevenue,
									currMonthsRevenue
								)}
								%
							</span>
						</p>
						<div>
							{loadingYearGraphicalData ? (
								<p style={{ textAlign: "center" }}>
									Loading...
								</p>
							) : (
								<Bar
									data={data(
										revenuesByMonth,
										monthlyLabels,
										"Total Collections - Monthly",
										["#3ABDA5", "#6204DC"]
									)}
									options={options}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Monthly (
							{new Date().getFullYear() - 1})
						</p>
						<p style={{ visibility: "hidden" }}>
							{formatAmount(currMonthsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevMonthsRevenue,
											currMonthsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevMonthsRevenue,
									currMonthsRevenue
								)}
								%
							</span>
						</p>
						<div>
							<Bar
								data={data(
									prevYearRevenueByMonth,
									monthlyLabels,
									`Total Collections - Monthly (${
										new Date().getFullYear() - 1
									})`,
									["#3ABDA5", "#6204DC"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
				<section
					className={styles.payment__gateways_breadown}
					title="Click on any row or figure to download breakdown in excel"
				>
					<div className={styles.today}>
						<p>Today</p>
						{payGatewayTodayRevenue?.length === 0 ? (
							<p>Loading...</p>
						) : (
							<>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_TODAY",
											"INTERSWITCH",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[0]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[0]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"REMITA_TODAY",
											"REMITA",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Remita
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[1]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[1]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "REMITA_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"FLUTTERWAVE_TODAY",
											"FLUTTERWAVE",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Flutterwave
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[3]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[3]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "FLUTTERWAVE_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.eTransact}
									onClick={() => {
										handleItemBreakDownClick3(
											"eTRANSACT_TODAY",
											"eTRANSACT",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										eTransact
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[4]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[4]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "eTRANSACT_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.QTB}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_QTB",
											"INTERSWITCH2",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch QTB
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[2]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[2]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_QTB" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								{/* <div className={styles.UP}
								onClick={() => {
									handleItemBreakDownClick2("UP_TODAY", 'UP', "today");
								}}														
							>
								<span className={styles.gateway__name}>UP</span>
								<span className={styles.gateway__amount}>
									&#8358;
									{formatAmount(
										payGatewayTodayRevenue[5]?.Amount
									)}
								</span>
								<span className={styles.gateway__percentage}>
									{payGatewayTodayRevenue[5]?.percentage}%
								</span>
								{loadingBrkDown === "UP_TODAY" && (
									<BounceLoader color="#36D7B7" size={20} />
								)}
							</div> */}
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"NextAutomate_TODAY",
											"Nextautomate",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										NextAutomate
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[6]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[6]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown ===
										"NextAutomate_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"Parklane_TODAY",
											"PARKLANE",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Parklane
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[7]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[7]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "Parklane_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"BLUETAG_TODAY",
											"BLUETAG",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										BlueTag
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[8]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[8]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "BLUETAG_TODAY" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"AUTOREG_TODAY",
											"AUTOREG",
											"today"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Autoreg
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayTodayRevenue[9]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayTodayRevenue[9]
													?.percentage
											}
											%
										</span>
										{loadingBrkDown === "AUTOREG_TODAY" && (
											<BounceLoader
												color="#36D7B7"
												size={20}
											/>
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div className={styles.wtd}>
						<p>WTD</p>
						{payGatewayWeekRevenue.length === 0 ? (
							<p>Loading...</p>
						) : (
							<>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_WEEK",
											"INTERSWITCH",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[0]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[0]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"REMITA_WEEK",
											"REMITA",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Remita
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[1]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[1]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "REMITA_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"FLUTTERWAVE_WEEK",
											"FLUTTERWAVE",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Flutterwave
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[3]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[3]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "FLUTTERWAVE_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.eTransact}
									onClick={() => {
										handleItemBreakDownClick3(
											"eTRANSACT_WEEK",
											"eTRANSACT",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										eTranzact
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[4]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[4]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "eTRANSACT_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.QTB}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_QTB",
											"INTERSWITCH2",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch QTB
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[2]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[2]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_QTB" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								{/* <div className={styles.UP}
								onClick={() => {
									handleItemBreakDownClick2("WeekUP", "week");
								}}
							>
								<span className={styles.gateway__name}>
									UP &nbsp;
								</span>
								<span className={styles.gateway__amount}>
									&#8358;
									{formatAmount(
										payGatewayWeekRevenue[5]?.Amount
									)}
								</span>
								<span className={styles.gateway__percentage}>
									{payGatewayWeekRevenue[5]?.percentage}%
								</span>
								{loadingBrkDown === "WeekUP" && (
									<BounceLoader color="#36D7B7" size={20} />
								)}
							</div> */}
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"NextAutomate_WEEK",
											"Nextautomate",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										NextAutomate
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[6]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[6]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "NextAutomate_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"Parklane_WEEK",
											"PARKLANE",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Parklane
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[7]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[7]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "Parklane_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"BLUETAG_WEEK",
											"BLUETAG",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										BlueTag
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[8]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[8]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "BLUETAG_WEEK" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"AUTOREG_WEEK",
											"AUTOREG",
											"week"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Autoreg
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayWeekRevenue[9]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayWeekRevenue[9]
													?.percentage
											}
											%
										</span>
										{loadingBrkDown === "AUTOREG_WEEK" && (
											<BounceLoader
												color="#36D7B7"
												size={20}
											/>
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div className={styles.mtd}>
						<p>MTD</p>
						{payGatewayMonthRevenue.length === 0 ? (
							<p>Loading...</p>
						) : (
							<>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_MONTH",
											"INTERSWITCH",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[0]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[0]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"REMITA_MONTH",
											"REMITA",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Remita &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[1]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[1]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "REMITA_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"FLUTTERWAVE_MONTH",
											"FLUTTERWAVE",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Flutterwave &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[3]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[3]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "FLUTTERWAVE_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.eTransact}
									onClick={() => {
										handleItemBreakDownClick3(
											"eTRANSACT_MONTH",
											"eTRANSACT",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										eTransact &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[4]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[4]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "eTRANSACT_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.QTB}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_QTB_MONTH",
											"INTERSWITCH2",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch QTB &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[2]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[2]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown ===
										"INTERSWITCH_QTB_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								{/* <div className={styles.UP}
								onClick={() => {
									handleItemBreakDownClick3("UP_MONTH",'UP' ,"curmth");
								}}
							>
								<span className={styles.gateway__name}>
									UP &nbsp;
								</span>
								<span className={styles.gateway__amount}>
									&#8358;
									{formatAmount(
										payGatewayMonthRevenue[5]?.Amount
									)}
								</span>
								<span className={styles.gateway__percentage}>
									{payGatewayMonthRevenue[5]?.percentage}%
								</span>
								{loadingBrkDown === "UP_MONTH" && (
									<BounceLoader color="#36D7B7" size={20} />
								)}
							</div> */}
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"NextAutomate_MONTH",
											"Nextautomate",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										NextAutomate
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[6]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[6]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown ===
										"NextAutomate_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"Parklane_MONTH",
											"PARKLANE",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Parklane
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[7]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[7]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "Parklane_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"BLUETAG_MONTH",
											"BLUETAG",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										BlueTag
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[8]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[8]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "BLUETAG_MONTH" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"AUTOREG_MONTH",
											"AUTOREG",
											"curmth"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Autoreg
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayMonthRevenue[9]
													?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayMonthRevenue[9]
													?.percentage
											}
											%
										</span>
										{loadingBrkDown === "AUTOREG_MONTH" && (
											<BounceLoader
												color="#36D7B7"
												size={20}
											/>
										)}
									</div>
								</div>
							</>
						)}
					</div>
					<div className={styles.ytd}>
						<p>YTD</p>
						{payGatewayYearRevenue.length === 0 ? (
							<p>Loading...</p>
						) : (
							<>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_YEAR",
											"INTERSWITCH",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[0]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[0]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "INTERSWITCH_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"REMITA_YEAR",
											"REMITA",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Remita &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[1]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[1]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "REMITA_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"FLUTTERWAVE_YEAR",
											"FLUTTERWAVE",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Flutterwave &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[3]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[3]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "FLUTTERWAVE_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.eTransact}
									onClick={() => {
										handleItemBreakDownClick3(
											"eTRANSACT_YEAR",
											"eTRANSACT",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										eTransact &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[4]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[4]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "eTRANSACT_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.QTB}
									onClick={() => {
										handleItemBreakDownClick3(
											"INTERSWITCH_QTB_YEAR",
											"INTERSWITCH2",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Interswitch QTB &nbsp;
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[2]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[2]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown ===
										"INTERSWITCH_QTB_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								{/* <div className={styles.UP}>
								<span className={styles.gateway__name}
									onClick={() => {
										handleItemBreakDownClick3("UP_YEAR", 'UP', "year");
									}}
								>
									UP &nbsp;
								</span>
								<span className={styles.gateway__amount}>
									&#8358;
									{formatAmount(
										payGatewayYearRevenue[5]?.Amount
									)}
								</span>
								<span className={styles.gateway__percentage}>
									{payGatewayYearRevenue[5]?.percentage}%
								</span>
								{loadingBrkDown === "UP_YEAR" && (
									<BounceLoader color="#36D7B7" size={20} />
								)}
							</div> */}
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"NextAutomate_YEAR",
											"Nextautomate",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										NextAutomate
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[6]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[6]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "NextAutomate_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.remita}
									onClick={() => {
										handleItemBreakDownClick3(
											"Parklane_YEAR",
											"PARKLANE",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Parklane
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[7]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[7]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "Parklane_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.flutterwave}
									onClick={() => {
										handleItemBreakDownClick3(
											"BLUETAG_YEAR",
											"BLUETAG",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										BlueTag
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[8]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[8]
													?.percentage
											}
											%
										</span>
									</div>
									{loadingBrkDown === "BLUETAG_YEAR" && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</div>
								<div
									className={styles.interswitch}
									onClick={() => {
										handleItemBreakDownClick3(
											"AUTOREG_YEAR",
											"AUTOREG",
											"year"
										);
									}}
								>
									<span className={styles.gateway__name}>
										Autoreg
									</span>
									<div>
										<span
											className={styles.gateway__amount}
										>
											&#8358;
											{formatAmount(
												payGatewayYearRevenue[9]?.Amount
											)}
										</span>
										<span
											className={
												styles.gateway__percentage
											}
										>
											{
												payGatewayYearRevenue[9]
													?.percentage
											}
											%
										</span>
										{loadingBrkDown === "AUTOREG_YEAR" && (
											<BounceLoader
												color="#36D7B7"
												size={20}
											/>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</section>
				<section
					className={styles.breakdowns}
					title="Click on any row or figure to download breakdown in excel"
				>
					<div
						className="spinner-container"
						title="Click on any row or figure to download breakdown in excel"
					>
						<h3>Current Month Revenue Heads Collections</h3>
						<ul>
							{currMonthRevBreakdown?.map((item) => (
								<li
									key={item.code}
									className={styles.breakdown__amt}
									onClick={() => {
										handleItemBreakDownClick(
											item.code,
											item.Description,
											"curmth"
										);
									}}
									disabled={loadingBrkDown === item.code}
								>
									<span>{item.Description}</span>
									<span>
										&#8358;
										{formatAmount(parseFloat(item.Amount))}
									</span>
									{loadingBrkDown ===
										`${item.code}curmth` && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</li>
							))}
						</ul>
					</div>
					<div
						className="spinner-container"
						title="Click on any row or figure to download breakdown in excel"
					>
						<h3>Previous Month Revenue Heads Collections</h3>
						<ul>
							{prevMonthRevBreakdown?.map((item) => (
								<li
									key={item.code}
									className={styles.breakdown__amt}
									onClick={() => {
										handleItemBreakDownClick(
											item.code,
											item.Description,
											"prevmth"
										);
									}}
								>
									<span>{item.Description}</span>
									<span>
										&#8358;
										{formatAmount(parseFloat(item.Amount))}
									</span>
									{loadingBrkDown ===
										`${item.code}prevmth` && (
										<BounceLoader
											color="#36D7B7"
											size={20}
										/>
									)}
								</li>
							))}
						</ul>
					</div>
				</section>
				<div className={styles.anssid__div}>
					<div className={styles.anssid__stat}>
						<img src={greenProfile} alt="green profile" />
						<div>
							<p>Total {coy_ANSSID} Created</p>
							<p>{todaysAnssid}</p>
						</div>
						<p>
							Today
							<span
								style={{
									color:
										percentIncrease(
											yesterdaysAnssid,
											todaysAnssid
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									yesterdaysAnssid,
									todaysAnssid
								)}
								%
							</span>
						</p>
					</div>
					<div className={styles.anssid__stat}>
						<img src={blueProfile} alt="blue profile" />
						<div>
							<p>Total {coy_ANSSID} Organisation</p>
							<p>
								{formatAmount(
									anssidBreakdown?.corporateCount,
									true
								)}
							</p>
						</div>
						<p>
							<span></span>
						</p>
					</div>
					<div className={styles.anssid__stat}>
						<img src={redProfile} alt="red profile" />
						<div>
							<p> Total {coy_ANSSID} Individuals</p>
							<p>
								{formatAmount(
									anssidBreakdown?.individualCount,
									true
								)}
							</p>
						</div>
						<p>
							<span></span>
						</p>
					</div>
				</div>

				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Quarterly (
							{new Date().getFullYear()})
						</p>
						<p>
							&#8358;{" "}
							{formatAmount(
								quarterlyRevenue[getCurrentQuarter() - 1]
							) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											quarterlyRevenue[
												getCurrentQuarter() - 2
											],
											quarterlyRevenue[
												getCurrentQuarter() - 1
											]
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									quarterlyRevenue[getCurrentQuarter() - 2],
									quarterlyRevenue[getCurrentQuarter() - 1]
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									quarterlyRevenue,
									quarterlyLabels,
									"",
									["#3ABDA5", "#2F9986", "#267C6C", "#1F6458"]
								)}
								options={options}
							/>
						</div>
					</div>
					<div className={chartStyles.stat}>
						<p>
							Total Collections - Quarterly (
							{new Date().getFullYear() - 1})
						</p>
						<p style={{ visibility: "hidden" }}>
							{formatAmount(currYearsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevYearsRevenue,
											currYearsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevYearsRevenue,
									currYearsRevenue
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									prevYearRevenueByQtr,
									quarterlyLabels,
									"",
									["#3ABDA5", "#2F9986", "#267C6C", "#1F6458"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
				<div className={chartStyles.top__stats}>
					<div className={chartStyles.stat}>
						<p>Total Collections - Yearly</p>
						<p>
							&#8358; {formatAmount(currYearsRevenue) || 0}{" "}
							<span
								style={{
									color:
										percentIncrease(
											prevYearsRevenue,
											currYearsRevenue
										) < 0
											? "red"
											: "#2F9986"
								}}
							>
								{percentIncrease(
									prevYearsRevenue,
									currYearsRevenue
								)}
								%
							</span>
						</p>
						<div className={styles.pie}>
							<Pie
								data={data(
									revenuesByYear,
									yearlyLabels,
									"Total Collections - Yearly",
									["#7F2536", "#9D2D42", "#C23852", "#EF4565"]
								)}
								options={options}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Dashboard;
