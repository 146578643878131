/* eslint-disable eqeqeq */
import { formatAmount } from "../../utils";
import Pagination from "./Pagination";
import styles from "./style.module.css";

const Table = ({
	headers,
	data = [],
	full,
	total = data?.length,
	pageSize = 20,
	onRowClick,
	billNoOfAmountPaidShowing,
	setBillNoOfAmountPaidShowing,
	amountPaid,
	onProfile,
	onPageChanged,
	onEdit,
	onView,
	onOthers,
	onH1,
	onDelete,
	onAddToPrint,
	onRemoveFromPrint,
	printArray,
	showingTotal = true
}) => {
	return (
		<>
			<table className={styles.table}>
				<thead>
					<tr className={styles.table__row}>
						{headers.map((header, i) => {
							if (full) {
								return <th key={i}>{header[0]}</th>;
							} else
								return i > 4 ? null : (
									<th key={i}>{header[0]}</th>
								);
						})}
						{(onEdit ||
							onView ||
							onDelete ||
							(onAddToPrint && onRemoveFromPrint) ||
							onProfile ||
							onOthers ||
							onH1) && <th>Action</th>}
					</tr>
				</thead>
				<tbody>
					{data?.map((each, i) => (
						<tr
							className={styles.table__row}
							key={i}
							onClick={() =>
								onRowClick ? onRowClick(each) : null
							}
						>
							{headers.map((header, i) => {
								const value = header[2]
									? header[2](each[header[1]])
									: each[header[1]];
								if (full) {
									return (
										<td
											key={i}
											className={
												value === "Pending"
													? styles.pending
													: value === "Approved"
														? styles.approved
														: value === "Denied"
															? styles.denied
															: null
											}
										>
											{header[1] === "filedStatus" ? (
												<p
													className={`
													${styles.filed__status__row}
													${value === "filed" ? styles.filed : styles.not__filed}
												`}
												>
													{value != undefined
														? value
														: "Not Filed"}
												</p>
											) : value != undefined ? (
												value
											) : (
												"N/A"
											)}
										</td>
									);
								} else
									return i > 4 ? null : (
										<td
											key={i}
											className={
												value === "Pending"
													? styles.pending
													: value === "Approved"
														? styles.approved
														: value === "Denied"
															? styles.denied
															: null
											}
										>
											{value != undefined ? value : "N/A"}
										</td>
									);
							})}
							{(onEdit ||
								onView ||
								onOthers ||
								onH1 ||
								onDelete ||
								(onAddToPrint && onRemoveFromPrint) ||
								onProfile) && (
								<td>
									{onView && (
										<button
											type="button"
											className={styles.view__btn}
											onClick={() => onView(each)}
										>
											View
										</button>
									)}
									{onEdit && (
										<button
											type="button"
											className={styles.edit__btn}
											onClick={() => onEdit(each)}
										>
											Edit
										</button>
									)}
									{onOthers && (
										<button
											type="button"
											className={styles.revoke__btn}
											onClick={() => onOthers(each)}
										>
											Revoke
										</button>
									)}
									{onH1 &&
										(each.FilingYear || each.filedYear) && (
											<button
												type="button"
												className={styles.h1__button}
												onClick={() => onH1(each)}
											>
												Download H1
											</button>
										)}
									{onDelete && (
										<button
											type="button"
											className={styles.revoke__btn}
											onClick={() => onDelete(each)}
										>
											Delete
										</button>
									)}
									{onAddToPrint && onRemoveFromPrint && (
										<button
											type="button"
											className={
												printArray.includes(
													each.BillNumber
												)
													? styles.removeFromPrint
													: styles.print__btn
											}
											onClick={
												printArray.includes(
													each.BillNumber
												)
													? () =>
															onRemoveFromPrint(
																each
															)
													: () => onAddToPrint(each)
											}
										>
											{printArray.includes(
												each.BillNumber
											)
												? "Remove From Print"
												: "Add To Print"}
										</button>
									)}
									{onProfile && (
										<button
											type="button"
											className={styles.profile__btn}
											onClick={() => onProfile(each)}
										>
											Profile
										</button>
									)}
								</td>
							)}
							{onRowClick &&
								billNoOfAmountPaidShowing ===
									(each.AssessmentCode ||
										each.BillNumber) && (
									<td className={styles.amountPaid__tooltip}>
										{amountPaid ? (
											<>
												<button
													onClick={(e) => {
														e.stopPropagation();
														setBillNoOfAmountPaidShowing(
															""
														);
													}}
												>
													x
												</button>
												Amount Paid: ₦
												{formatAmount(amountPaid)}
											</>
										) : (
											"...."
										)}
									</td>
								)}
						</tr>
					))}
				</tbody>
			</table>
			{full && (
				<footer className={styles.table__footer}>
					{showingTotal && (
						<p>
							Displaying {data?.length} out of {total}
						</p>
					)}
					{total && (
						<Pagination
							totalRecords={total}
							pageLimit={pageSize}
							pageNeighbours={1}
							onPageChanged={onPageChanged}
						/>
					)}
				</footer>
			)}
		</>
	);
};

export default Table;
