import React from "react";
import axios from "axios";
import Table3 from "../../../components/Table3";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";
import anssidStyles from "../../StateTIN/style.module.css";
import PaymentListModal from "./paymentListModal";
import Flutterwave from "./flutterwave";
import Etransacpayment from "./etransac";
import InterSwitcheb from "./interswitchpay";
import Remitacheckout from "./remita";
import Modal from "../../../components/Modal/regular";

const CorporatePayeRecord = () => {
	const corporateESBN = JSON.parse(localStorage.getItem("user_info")).anssid;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [StaffName, setStaffName] = useState("");
	const [ESBN, setESBN] = useState("");
	const [finishESBN, setFinishESBN] = useState("0");
	const [pgESBN, setpgESBN] = useState("0");
	const [pgESBNname, setpgESBNname] = useState("0");
	// eslint-disable-next-line
	const [companyName, setCompanyName] = useState("");
	// eslint-disable-next-line
	const [esbnName, setESBNName] = useState("");
	// eslint-disable-next-line
	const [BillNumber, setBillNumber] = useState("");
	// eslint-disable-next-line
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	// eslint-disable-next-line
	const [paymentStatus, setPaymentStatus] = useState("");
	// eslint-disable-next-line
	const [taxYear, setTaxYear] = useState("");
	// eslint-disable-next-line
	const [revokeModalStatus, setRevokeModalStatus] = useState({
		revoking: false,
		showing: false,
		data: null
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	// const accttype = JSON.parse(localStorage.getItem("user_info")).account_type;

	useEffect(
		() => {
			// getCorpESBN(JSON.parse(localStorage.getItem("user_info")).anssid);
			getCorpESBN(JSON.parse(localStorage.getItem("user_info")).anssid);
			getAssessmentRecords()();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[finished === "1"],
		[finishESBN === "2"]
	);

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();
			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			// let query = `page=${currentPage}&pageSize=20&`;
			// if (StaffName) {
			// 	query += `StaffName=${StaffName}&`;
			// }

			// if (companyName) {
			// 	query += `Name=${companyName}&`;
			// }

			// if (BillNumber) {
			// 	query += `BillNumber=${BillNumber}&`;
			// }

			// if (paymentStatus) {
			// 	query += `PaymentStatus=${paymentStatus}&`;
			// }
			// .get(`/dareports/tmspayedisplay?${query}`,
			// `/dareports/tmspayedisplayDetails/${userId}/${currentYear}/ALL`,
			//alpha.norlics.ng/api/dareports/tmspayedisplay?paymentStatus=Pending

			// alpha.norlics.ng/api/dareports/tmspayedisplayDetails/816/2023/August?Name=Ayo

			try {
				const accessToken = localStorage.getItem("access_token");
				let { data, total } = await axios
					.get(`/dareports/tmspayedisplay?ESBN=${corporateESBN}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });
				// console.log("API Response:", data);

				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				setTotal(total);
			} catch (err) {
				console.error(err);
			} finally {
				setFinished("1");
				// setLoading(false);
			}
		};

	const getCorpESBN = async (cESBN) => {
		try {
			const res = await axios
				.get(`/users/anssid?anssid=${cESBN}`)
				.then((res) => (res.data || {}).data);

			setESBNName(res.name);
			setpgESBN(res.anssid);
			setpgESBNname(res.name);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setFinishESBN("2");
			// setSearching(false);
		}
	};

	const clearFilters = () => {
		setESBN("");
		setCompanyName("");
		setBillNumber("");
		setPaymentStatus("");

		filterRef.current.classList.toggle(styles1.no__display);
		// setFiltersCleared(true);
	};

	const headers = [
		["Bill Number", "BillNumber"],
		// ["Fiscal Monthssss", "fiscalMth"],
		["Fiscal Year ", "fiscalYear"],
		["Total Num of Staff", "totStaffNo"],
		["Total Montly charge", "totMthAmtBilled"],
		["Total Yearly charge", "totAmtCharged"],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"]
	];

	// const [dataToPayFor, setDataToPayFor] = useState(null);
	const [modalToShow, setModalToShow] = useState(null);

	return (
		<section className={styles.corporatePayeRecord}>
			{Boolean(modalToShow) && (
				<Modal showing={Boolean(modalToShow)} toggle={setModalToShow}>
					{modalToShow === "flutterWave" && (
						<Flutterwave
							// data={dataToPayFor}
							setModalToShow={setModalToShow}
						/>
					)}
					{modalToShow === "remita" && (
						<Remitacheckout
							// data={dataToPayFor}
							setModalToShow={setModalToShow}
						/>
					)}
					{modalToShow === "interswitch" && (
						<InterSwitcheb
							// data={dataToPayFor}
							setModalToShow={setModalToShow}
						/>
					)}
					{modalToShow === "transact" && (
						<Etransacpayment
							// data={dataToPayF
							setModalToShow={setModalToShow}
						/>
					)}
					{modalToShow === "gatewayList" && (
						<PaymentListModal setModalToShow={setModalToShow} />
					)}
				</Modal>
			)}
			<div className={`styles1.action__btns ${styles.header__btns}`}>
				<button
					className={`primary__btn ${anssidStyles.create__btn}`}
					// style={{ paddingLeft: "15px", paddingRight: "15px" }}
					onClick={() => {
						history.push(`/PAYE/PayeSchedule`, {
							ESBN: pgESBN,
							esbnName: pgESBNname
						});
					}}
				>
					PAYE Schedule
				</button>
				<section
					className={`styles1.action__btns ${styles.header__btns}`}
				>
					<div className={styles1.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles1.no__display
								);
							}}
							className={`${styles1.filter__button} ${styles.filter__button}`}
						>
							Filter
						</button>

						<div
							ref={filterRef}
							className={[
								styles1.filter__div,
								styles1.no__display
							].join(" ")}
						>
							<div className={styles1.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles1.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles1.clear__filter_fields}
								onClick={clearFilters}
								disabled={
									!ESBN &&
									// !companyName &&
									// !BillNumber &&
									// !paymentStatus &&
									!taxYear
								}
							>
								Clear Filter fields
							</button>
							<p>By StaffName</p>
							<form onSubmit={(e) => e.preventDefault()}>
								<div>
									<label htmlFor="StaffName">
										Enter Staff Name
									</label>
									<input
										name="StaffName"
										id="StaffName"
										type="text"
										value={StaffName}
										onChange={(e) =>
											setStaffName(e.target.value)
										}
										placeholder="e.g Uga Ada "
									/>
								</div>
							</form>
							{/* <p>By Bill Number</p> */}
							{/* <form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="BillNumber	">
									Company Bill Number
								</label>
								<input
									name="	"
									id="BillNumber"
									type="number"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg.12345678"
								/>
							</div>
						</form> */}
							<button
								className={[
									styles1.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={getAssessmentRecords()}
							>
								Search
							</button>
						</div>
					</div>
				</section>
			</div>
			<div style={{ margin: "0px" }}>
				<div className={styles1.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table3
							headers={headers}
							data={data}
							full
							pageSize={20}
							total={total}
							onPageChanged={getAssessmentRecords}
							// onPay={(data) => {
							// 	setDataToPayFor(data);
							// 	setModalToShow("gatewayList");
							// }}
							// onPay={(data) => {
							// 	history.push(
							// 		`/direct-assessment/create-direct-assessment`,
							// 		{
							// 			...data,
							// 			ESBN: data.ESBN,
							// 			edit: true
							// 		}
							// 	);
							// }}

							onView={(data) => {
								console.log(data);
								history.push(`/Paye-Review`, {
									...data,
									// accttype,
									view: true
								});
							}}
							onOthers={(data) =>
								setRevokeModalStatus((revokeStatus) => ({
									...revokeStatus,
									showing: true,
									data
								}))
							}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default CorporatePayeRecord;
