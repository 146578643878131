import React from "react";
import styles from "./style.module.css";
import Good from "../assets/Home/goodtick.png";
import { useHistory } from "react-router";

const Paymentsuccessful = () => {
	const history = useHistory();
	return (
		<>
			<div className={[styles.success_payment]}>
				<h3>Payment is successful</h3>
				<img
					className={[styles.img_payment]}
					src={Good}
					alt="Good tick"
				/>
			</div>
			<div>
				<br />
				<br />
				<center>
					<button
						className={[styles.btn]}
						onClick={() => history.push("/home")}
					>
						Return Home
					</button>
				</center>
			</div>
		</>
	);
};

export default Paymentsuccessful;
