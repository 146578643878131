/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import styles1 from "../Identity-management-individual/style.module.css";
import style from "./style.module.css";
import axios from "axios";
import Alert from "../components/Alert";
import Modal from "../components/Modal";

const coylogo = process.env.REACT_APP_COYNAME;

let imgpath = "../assets/Home/" + process.env.REACT_APP_COYLOGO;

const InterSwitch = () => {
	const [BillNumber, setBillNumber] = useState("");
	const [billNumberDetails, setBillNumberDetails] = useState(null);
	const [ownersesbn, setownersesbn] = useState("");
	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [ESBNDetails, setESBNDetails] = useState(null);
	const [isConfirmingDetails, setIsConfirmingDetails] = useState(false);
	const [loading, setLoading] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	function randomReference() {
		var length = 10;
		var chars =
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var result = "";
		for (var i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	function paymentCallback(response) {
		console.log(response);

		const requestData = {
			pymtgateway: "PAYDIRECT",
			billno: BillNumber,
			billerfoneno: ESBNDetails?.phonenumber,
			billeremail: ESBNDetails?.email,
			amt: amount,
			appramt: amount,
			bpresp: "",
			bptrxref: " ",
			cardno: " ",
			description: "Approved by Financial Institution",
			payref: "FBN|WEB|MX19329|03-11-2023|474560954|654074",
			rechpin: " ",
			mac: "",
			apiresponse: "00",
			retref: "000106923853",
			txnref: "000106923853"
		};

		// API endpoint
		const apiEndpoint = `https://alpha.norlics.ng/api/taxpaid/`;

		// Create a request object with the appropriate method, headers, and body
		const request = new Request(apiEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "accesstoken"
			},
			body: JSON.stringify(requestData)
		});

		// Send the POST request
		fetch(request)
			.then((response) => {
				if (response.ok) {
					console.log("Data sent successfully");
					// Handle success if needed
				} else {
					console.error("Failed to send data to the API");
					// Handle errors if needed
				}
			})
			.catch((error) => {
				console.error("An error occurred:", error);
				// Handle network errors or other issues if needed
			});
	}

	const confirmDetails = async (e) => {
		e.preventDefault();
		setIsConfirmingDetails(true);

		try {
			//check for Owneresbn
			const esbnDeets = await axios
				.get(`/users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			//check for billing number
			const billingDeets = await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => (res.data || {}).data);

			setESBNDetails(esbnDeets);
			setBillNumberDetails(billingDeets);
			setAmount(billingDeets?.Amount);
			setName(`${esbnDeets?.firstname} ${esbnDeets?.surname}`);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setIsConfirmingDetails(false);
		}
	};

	useEffect(() => {
		const interswitchScript = document.createElement("script");
		interswitchScript.src = `https://newwebpay.interswitchng.com/inline-checkout.js`;
		interswitchScript.async = true;
		document.body.appendChild(interswitchScript);
		interswitchScript.onload = () =>
			console.log("interswitchScript loaded");
	}, []);

	//sample payment request
	const samplePaymentRequest = {
		merchant_code: "MX112693",
		pay_item_id: "Default_Payable_MX112693",
		txn_ref: randomReference(),
		amount: amount * 100,
		cust_id: ESBNDetails?.email,
		currency: 566,
		site_redirect_url: "http://localhost:3000",
		onComplete: paymentCallback,
		mode: "LIVE"
	};

	// const handleInterswitchModal = (e) => {
	//   e.preventDefault();

	//   if (window?.webpayCheckout) {
	//     window?.webpayCheckout(samplePaymentRequest);
	//   } else {
	//     console.log("Error");
	//   }
	// };

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			<main className={[style.main].join("")}>
				<center>
					<div>
						<h4 className={[style.h4].join("")}>
							<b>Pay with ESBN and Bill Number</b>
						</h4>
					</div>
				</center>
				<form
					className={[styles.form].join(" ")}
					onSubmit={(e) => confirmDetails(e)}
				>
					<div className={[styles1.double__inputs].join(" ")}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ownersesbn"
							>
								ESBN <span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								onChange={(e) => setownersesbn(e.target.value)}
								id="ownersesbn"
								placeholder="enter your ESBN"
								required
								value={ownersesbn}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="BillNumber"
							>
								Bill Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								onChange={(e) => setBillNumber(e.target.value)}
								id="BillNumber"
								placeholder="Enter bill number"
								required
								value={BillNumber}
							/>
						</div>
					</div>
					<br />
					<center>
						<button
							type="submit"
							className={[
								"primary__btn",
								styles.submit__btn
							].join(" ")}
							disabled={!BillNumber || !ownersesbn}
						>
							{isConfirmingDetails
								? "Confirming..."
								: "Confirm Details"}
						</button>
					</center>
				</form>
				<div className={[styles1.double__inputs].join(" ")}>
					{/* <p>
            Name: {ESBNDetails?.firstname}
            {ESBNDetails?.surname}
          </p> */}
					<div>
						<label className={styles.input__label} htmlFor="name">
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							id="amount"
							placeholder="Enter Name"
							required
							disabled
						/>
					</div>

					<div>
						<label className={styles.input__label} htmlFor="amount">
							Amount <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							id="amount"
							placeholder="Enter amount"
							required
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				<center>
					{ESBNDetails && billNumberDetails && (
						<button
							onClick={() =>
								window?.webpayCheckout(samplePaymentRequest)
							}
							className={[style.btn]}
						>
							Pay now
						</button>
					)}
				</center>
			</main>
		</>
	);
};

export default InterSwitch;
