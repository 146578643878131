import React from "react";
import Flutterwave from "./flutterwave";
import style from "./style.module.css";

const Flutterwavepage = () => {
	return (
		<div className={[style.row]}>
			<Flutterwave />
		</div>
	);
};

export default Flutterwavepage;
