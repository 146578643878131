import styles from "./styles.module.css";

export const InvoiceModal = ({ showing, toggle, children }) => {
	return (
		<>
			<div className={styles.invoice__children}>{children}</div>
			<div
				className={styles.modal}
				onClick={() => toggle(!showing)}
			></div>
		</>
	);
};

