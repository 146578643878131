import XLSX from "xlsx";

export function formatDate(date) {
	const [d] = new Date(date).toISOString().split("T");
	return d;
}

export const formatAmount = (amount, noDecimal) =>
	Number(parseFloat(amount || 0).toFixed(2)).toLocaleString(undefined, {
		minimumFractionDigits: noDecimal ? 0 : 2
	});

export const percentIncrease = (prev, curr) => {
	if (prev < 1) return 0;

	return formatAmount(((+curr - +prev) / +prev) * 100);
};

export const exportExcel = (data, headers, fileName) => {
	if (!(data || []).length) return;

	const ws = XLSX.utils.json_to_sheet(data);
	ws["!cols"] = autoFitColFromHeader(headers);
	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws);
	return XLSX.writeFile(wb, fileName);
};

export const autoFitColFromHeader = (headers = []) => {
	return headers.map((header) => ({ wch: header[0].length + 2 }));
};

export const remapObj = (obj = [], headers = []) => {
	return obj.map((data) => {
		return headers.reduce((acc, header) => {
			acc[header[0]] =
				typeof header[2] === "function"
					? header[2](data[header[1]])
					: data[header[1]] || "N/A";
			return acc;
		}, {});
	});
};
