import React, { createRef, useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Header from "../components/header";
import Sidenav from "../components/sidenav";
import Home from "./Home";
import MDA from "./MDAs";
import IndividualANSSID from "./StateTIN/individual";
import CorporateANSSID from "./StateTIN/corporate";
import ANSSIDAudit from "./StateTIN/audit";
import AuthorizeANSID from "./StateTIN/authorize";
import IndividualAuditDetails from "./StateTIN/individualAuditDetails";
import CorporateAuditDetails from "./StateTIN/corporateAuditDetails";
import RATs from "./RATs";
import JBTs from "./JBTs";
import TotalCollections from "./Total-Collections";
import ClearedBills from "./Bills/cleared";
import UnclearedBills from "./Bills/uncleared";
import CreateMDA from "./MDAs/create";
import CreateJTB from "./JBTs/create";
import User from "./user";
import Claim from "./Claim";
import Role from "./roles";
import AdminChangePassword from "./Admin/admin.change.password";
import CreateUser from "./user/create";
import CreateClaim from "./Claim/create";
import CreateRole from "./roles/create";
import Analytics from "./analysis";
import LGAAnalytics from "./analysis/lga";
import MDAAnalytics from "./analysis/mda";
import RevenueHeadAnalytics from "./analysis/revenuehead";
import HandlingSystemAnalytics from "./analysis/handlingsystem";
import BillingGroupAnalytics from "./analysis/billinggroup";
import MDAOptionsAnalytics from "./analysis/mda.options";
import Summary from "./summary";
import ANSSIDAgent from "./ANSSIDAgent";
import DeactivatedAgents from "./DeactivatedAgent";
import UserDetails from "./UserDetails";
import CompanyDetails from "./CompanyDetails";
import UserSidenav from "../components/sidenav/user";
import Support from "./Support";

import CreateDirectAssessment from "../Direct-assessment/Create-direct-assessment";
import Authorise from "../Direct-assessment/Authorize";
import DirectAssessmentSettings from "../Direct-assessment/Settings";
import AssessmentRecords from "../Direct-assessment/Assessment-records";
import Match from "../Direct-assessment/Match";

import GenerateDAManualEntryCertificate from "./Certificate/generate.manual.da.cert";
import DAManualEntryCertificate from "./Certificate/da.manual.entry";
import AutoDACertificate from "./Certificate/auto.da.certificate";
import ManualDACertificate from "./Certificate/manual.da.certificate";
import GenerateAutoDACertificate from "./Certificate/generate.auto.da.cert";
import AuthoriseDACertificates from "./Certificate/authorise.da";

import PAYE from "./PAYE/Create-PAYE-assessment";

import Receipt from "./Receipt";
import ReceiptPymtRef from "./ReceiptPymtRef/receiptref";
// import ReceiptPymtOurs from "./ReceiptPymtRef/receiptours";
import ReceiptPymtSwitch from "./ReceiptPymtRef/receiptswitch";
import Mdareceipt from "./ReceiptPymtRef/mda.receipt";
import ReceiptPymtInv from "./ReceiptPymtRef/receiptinv";
// import Receipts from "./ReceiptPymtRef/receipts";
import SingleInputReceipt from "./ReceiptPymtRef/single.input.receipt";
import IDDetails from "./Settings/id.details";
import ANSSIDSetup from "./Settings/stateTIN.setup";
import MonthlySalaryRange from "./Settings/monthly.salary.range";
import RevenueDetails from "./Settings/revenue.details";
import ChangePassword from "./ChangePassword";

import SpecialDashboard from "./SpecialDashboard";

import TotalCollectionsIcon from "../assets/Header/totalCollections.svg";
import BillsIcon from "../assets/Header/bills.svg";
import MDAIcon from "../assets/Header/mdas.svg";
import ANSSIDIcon from "../assets/Header/anssid.svg";
import AnalyticsIcon from "../assets/Header/analytics.svg";
import DashboardIcon from "../assets/Header/dashboard.svg";
import UserManagementIcon from "../assets/Header/userManagement.svg";
import ReceiptIcon from "../assets/Header/receipts.svg";
import SettingsIcon from "../assets/Header/settings.svg";
import ChangePasswordIcon from "../assets/Header/changePassword.svg";
import AnnualReturnsIcon from "../assets/Header/annualReturns.svg";

import styles from "./style.module.css";
import Preparebill from "./Multi-company/preparebill";
import MultiCompanySettings from "./Multi-company/settings";
import Profile from "./Multi-company/profile";
import CreateMulticompany from "./Multi-company/create";
import CreateBill from "./Multi-company/createbill";
import ViewBill from "./Multi-company/billActions/viewBill";
import EditBill from "./Multi-company/billActions/editBill";
import ViewBill4NewHaven from "./Multi-company/billActions4NewHaven/viewBill";
import EditBill4NewHaven from "./Multi-company/billActions4NewHaven/editBill";
import CreateWaterBill from "./Multi-company/createWaterBill";
import NewHavenShops from "./Multi-company/newHavenCreate";
import CreateNewHavenShops from "./Multi-company/newHavenBillCreate";
import PayeRecords from "./PAYE/Paye-Record";
import CorporatePayeRecord from "./PAYE/Paye-Record/corporatePayeRecord";
import CreatePAYEAssessment from "./PAYE/Create-PAYE-assessment";
import CorporateView from "./PAYE/Paye-Record/corporateView";
import CorporateProfiling from "./StateTIN/corporate.profiling";
import Authorisation from "./PAYE/Authorisation";
import Auth from "./PAYE/Authorisation/auth";
// import Auth from "./PAYE/Paye-Record/auth";
import Authoriser from "../Maker-checker/authoriser";
import Inputter from "../Maker-checker/inputter";
import Addnew from "../Maker-checker/addnew";
import { AnnualReturnsSearch } from "./AnnualReturns";
// import { PayeeCompliance } from "./AnnualReturns/payeeCompliance";
// import { WhtCompliance } from "./AnnualReturns/whtCompliance";
import { EmployeeCompliance } from "./AnnualReturns/employee";
import { ViewFiling } from "./AnnualReturns/viewFiling";
import { ViewEmployees } from "./AnnualReturns/viewEmployees";
import { EditEmployeeDetails } from "./AnnualReturns/editEmployeeDetails";
import Consolidated from "./DemandNotice/Consolidation/consolidated";
import GenerateConsolidated from "./DemandNotice/Consolidation/generate-consolidated";
import ConsolidatedView from "./DemandNotice/Consolidation/consolidatedView";
// import { AnnualReturnsCorporate } from "./AnnualReturns/annualReturnsCorporate";
import { FileAnnualReturns } from "./AnnualReturns/fileAnnualReturns";
import GenReceipt from "../Maker-checker/genreceipt";
import WaterBill from "./Multi-company/WaterBill";
import Water from "./Multi-company/water";
import { AdminFileAnnualReturn } from "./AnnualReturns/adminFileAnnualReturn";
import Newhavenshops from "./Multi-company/newhavenshops";
import Createnewhaven from "./Multi-company/createnewhaven";
import EditMaker from "../Maker-checker/edit";
import ViewMaker from "../Maker-checker/view";
import ReducingBalanceReceipt from "../ReducingBalance/reducingBalanceR";

// import ViewProfile from "./Multi-company/profileActions/viewProfile";
// import EditProfile from "./Multi-company/profileActions/editProfile";
const coy_ANSSID = process.env.REACT_APP_ANSSID;

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Dashboard = () => {
	const [sidebarOpen, toggleSidebar] = useState(window.innerWidth > 768);
	const { pathname } = useLocation();
	const { path } = useRouteMatch();

	const navRef = createRef();
	const menuRef = createRef();

	const isUser = /^(individual)|(corporate)$/i.test(userInfo?.account_type);
	const isCorporate = /^corporate$/i.test(userInfo?.account_type);

	const isGovernor = userInfo?.roles?.[0]?.Claims?.find((cl) =>
		/governor/i.test(cl.Name)
	);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const hasDashboard = Boolean(
		Object.keys(allowedRoutes).find((key) => key === "/")
	);

	const routes = {
		[`${path}`]: {
			title: isUser ? "Home" : "Dashboard"
		},
		[`${path}details`]: {
			title: "Details",
			component: isCorporate ? CompanyDetails : UserDetails
		},
		[`${path}password`]: {
			title: "Change Password",
			component: ChangePassword
		},
		[`${path}support`]: {
			title: "Contact Support",
			component: Support
		},
		[`${path}MyPaye-Record`]: {
			title: "My Paye Record",
			component: CorporatePayeRecord
		},
		// [`${path}annual-returns-corporate`]: {
		// 	title: "Annual Returns",
		// 	component: AnnualReturnsCorporate
		// },
		// [`${path}file-annual-returns`]: {
		// 	title: "File Annual Returns",
		// 	component: FileAnnualReturns
		// },
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: CreatePAYEAssessment
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: CorporateView
		}
	};

	const adminRoutes = {
		[`${path}`]: {
			title: "Dashboard",
			icon: DashboardIcon
		},
		[`${path}total-collections`]: {
			title: "Total Collections",
			component: TotalCollections,
			icon: TotalCollectionsIcon
		},
		[`${path}bills/cleared-bills`]: {
			title: "Cleared Bills",
			icon: BillsIcon,
			component: ClearedBills
		},
		[`${path}bills/uncleared-bills`]: {
			title: "Uncleared Bills",
			icon: BillsIcon,
			component: UnclearedBills
		},
		[`${path}mdas`]: {
			title: "MDA",
			icon: MDAIcon,
			component: MDA
		},
		[`${path}annual-returns/admin-filing/file-annual-returns`]: {
			title: "Annual Returns Filing",
			icon: AnnualReturnsIcon,
			component: FileAnnualReturns
		},
		[`${path}annual-returns/admin-filing`]: {
			title: "Annual Returns Filing",
			icon: AnnualReturnsIcon,
			component: AdminFileAnnualReturn
		},
		[`${path}annual-returns/search`]: {
			title: "Annual Returns Search",
			icon: AnnualReturnsIcon,
			component: AnnualReturnsSearch
		},
		[`${path}annual-returns/view`]: {
			title: "Annual Returns View",
			icon: AnnualReturnsIcon,
			component: ViewFiling
		},
		[`${path}annual-returns/employee`]: {
			title: "Annual Returns Employee",
			icon: AnnualReturnsIcon,
			component: EmployeeCompliance
		},
		[`${path}annual-returns/view-employees`]: {
			title: "Annual Returns Employee",
			icon: AnnualReturnsIcon,
			component: ViewEmployees
		},
		[`${path}annual-returns/employee-details`]: {
			title: "Employee Details",
			icon: AnnualReturnsIcon,
			component: EditEmployeeDetails
		},
		// [`${path}annual-returns/wht-compliance`]: {
		// 	title: "WHT Compliance",
		// 	icon: AnnualReturnsIcon,
		// 	component: WhtCompliance
		// },
		// [`${path}annual-returns/paye-compliance`]: {
		// 	title: "PAYE Compliance",
		// 	icon: AnnualReturnsIcon,
		// 	component: PayeeCompliance
		// },

		[`${path}PAYE/create-PAYE-assessment`]: {
			title: "Create PAYE Assessment",
			icon: MDAIcon,
			component: PAYE
		},
		[`${path}create-mda`]: {
			title: "Create MDA",
			icon: MDAIcon,
			component: CreateMDA
		},
		[`${path}statetin/individual`]: {
			title: `${coy_ANSSID} - Individual`,
			icon: ANSSIDIcon,
			component: IndividualANSSID
		},
		[`${path}statetin/corporate`]: {
			title: `${coy_ANSSID} - Organization`,
			icon: ANSSIDIcon,
			component: CorporateANSSID
		},
		[`${path}statetin/audit`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: ANSSIDAudit
		},
		[`${path}statetin/authorize`]: {
			title: `${coy_ANSSID} - Authorize`,
			icon: ANSSIDIcon,
			component: AuthorizeANSID
		},
		[`${path}audit-statetin/individual/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: IndividualAuditDetails
		},
		[`${path}audit-statetin/corporate/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: CorporateAuditDetails
		},
		[`${path}certificate/authorise/da`]: {
			title: `Authorise Certificates`,
			icon: ReceiptIcon,
			component: AuthoriseDACertificates
		},
		[`${path}certificate/da/generate`]: {
			title: `Generate Automated DA Certificate`,
			icon: ReceiptIcon,
			component: GenerateAutoDACertificate
		},
		[`${path}certificate/da`]: {
			title: `Direct Assessment Automated Certificate`,
			icon: ReceiptIcon,
			component: AutoDACertificate
		},
		[`${path}certificate/da-manual-entry-list`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: ManualDACertificate
		},
		[`${path}certificate/da-manual-entry`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: DAManualEntryCertificate
		},
		[`${path}certificate/generate-da-manual-entry-certificate`]: {
			title: `Generate Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: GenerateDAManualEntryCertificate
		},
		[`${path}rats`]: {
			title: "RAT",
			component: RATs
		},
		[`${path}jtbs`]: {
			title: "JTB",
			component: JBTs
		},
		[`${path}analytics`]: {
			title: "Analytics",
			icon: AnalyticsIcon,
			component: Analytics
		},
		[`${path}lgaanalytics`]: {
			title: "LGA Analytics",
			icon: AnalyticsIcon,
			component: LGAAnalytics
		},
		[`${path}mdaanalytics`]: {
			title: "MDA Analytics",
			icon: AnalyticsIcon,
			component: MDAAnalytics
		},
		[`${path}revenueheadanalytics`]: {
			title: "Revenue Head Analytics",
			icon: AnalyticsIcon,
			component: RevenueHeadAnalytics
		},
		[`${path}handlingsystemanalytics`]: {
			title: "Handling System Analytics",
			icon: AnalyticsIcon,
			component: HandlingSystemAnalytics
		},
		[`${path}billgroupanalytics`]: {
			title: "Billing Group Analytics",
			icon: AnalyticsIcon,
			component: BillingGroupAnalytics
		},
		[`${path}mdaoptionsanalytics`]: {
			title: "Top & Bottom MDAs",
			icon: AnalyticsIcon,
			component: MDAOptionsAnalytics
		},
		[`${path}create-multicoy`]: {
			title: `Create MDA`,
			icon: MDAIcon,
			component: CreateMulticompany
		},
		[`${path}create-bill`]: {
			title: `Prepare Bill`,
			icon: MDAIcon,
			component: CreateBill
		},
		[`${path}multi-company/prepare-bill`]: {
			title: `Prepare Bill`,
			icon: MDAIcon,
			component: CreateWaterBill
		},
		[`${path}multi-company/new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: NewHavenShops
		},
		[`${path}multi-company/create-new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: CreateNewHavenShops
		},
		[`${path}multi-company/water-bill`]: {
			title: `Water Bill`,
			icon: MDAIcon,
			component: WaterBill
		},
		[`${path}multi-company/water`]: {
			title: `Water`,
			icon: MDAIcon,
			component: Water
		},
		[`${path}multi-company/billing/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: ViewBill
		},
		[`${path}multi-company/billing/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: EditBill
		},
		[`${path}multi-company/billing4NewHaven/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: ViewBill4NewHaven
		},
		[`${path}multi-company/billing4NewHaven/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: EditBill4NewHaven
		},
		[`${path}Multi-company/New-haven-shops`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: Newhavenshops
		},
		[`${path}create-new-haven-shops`]: {
			title: `Add New Haven Shops`,
			icon: MDAIcon,
			component: Createnewhaven
		},

		// [`${path}multi-company/profile/view`]:{
		// 	title: `View Profile`,
		// 	icon: MDAIcon,
		// 	component: ViewProfile
		// },

		// [`${path}multi-company/profile/edit`]:{
		// 	title: `Edit Profile`,
		// 	icon: MDAIcon,
		// 	component: EditProfile
		// },

		//reducing-balance
		[`${path}reducing-balance/reducing-balance-receipts`]: {
			title: `Reducing Balance Receipt`,
			component: ReducingBalanceReceipt
		},
		[`${path}Maker-checker/authoriser`]: {
			title: `Authoriser`,
			component: Authoriser
		},
		[`${path}Maker-checker/inputter`]: {
			title: `Inputter`,
			component: Inputter
		},
		[`${path}Maker-checker/genreceipt`]: {
			title: `Generate Reciept`,
			component: GenReceipt
		},
		[`${path}addnew`]: {
			title: `Add New`,
			icon: MDAIcon,
			component: Addnew
		},

		[`${path}maker-checker/edit/:id`]: {
			title: `Edit-Maker-checker`,
			component: EditMaker
		},
		[`${path}maker-checker/view/:id`]: {
			title: `View-Maker-checker`,
			component: ViewMaker
		},
		[`${path}multi-company/billing`]: {
			title: `Bills`,
			component: Preparebill
		},
		[`${path}multi-company/mdaprofile`]: {
			title: `MDA Profile`,
			component: Profile
		},
		[`${path}multi-company/settings`]: {
			title: `Settings`,
			icon: UserManagementIcon,
			component: MultiCompanySettings
		},
		[`${path}create-jtb`]: {
			title: "Create JTB",
			component: CreateJTB
		},
		[`${path}user-management/manage-users`]: {
			title: "Manage Users",
			icon: UserManagementIcon,
			component: User
		},
		[`${path}user-management/manage-claims`]: {
			title: "Manage Claims",
			icon: UserManagementIcon,
			component: Claim
		},
		[`${path}user-management/statetin-agent`]: {
			title: `${coy_ANSSID} Agent`,
			icon: UserManagementIcon,
			component: ANSSIDAgent
		},
		[`${path}user-management/deactivate-agent`]: {
			title: "Deactivated Agent",
			icon: UserManagementIcon,
			component: DeactivatedAgents
		},
		[`${path}user-management/manage-roles`]: {
			title: "Manage Roles",
			icon: UserManagementIcon,
			component: Role
		},
		[`${path}direct-assessment/create-direct-assessment`]: {
			title: "Create Direct Assessment",
			icon: UserManagementIcon,
			component: CreateDirectAssessment
		},
		[`${path}direct-assessment/authorize-direct-assessment`]: {
			title: "Authorise Direct Assessment",
			icon: UserManagementIcon,
			component: Authorise
		},
		[`${path}direct-assessment/settings`]: {
			title: "Settings",
			icon: UserManagementIcon,
			component: DirectAssessmentSettings
		},
		[`${path}direct-assessment/records`]: {
			title: "Assessment Records",
			icon: UserManagementIcon,
			component: AssessmentRecords
		},
		[`${path}direct-assessment/match`]: {
			title: "Match Payment Ref & Bill Number",
			icon: UserManagementIcon,
			component: Match
		},
		[`${path}user-management/admin-change-password`]: {
			title: "Admin Change Password",
			icon: UserManagementIcon,
			component: AdminChangePassword
		},
		[`${path}create-user`]: {
			title: "Create User",
			icon: UserManagementIcon,
			component: CreateUser
		},
		[`${path}create-claim`]: {
			title: "Create Claim",
			component: CreateClaim
		},
		[`${path}create-role`]: {
			title: "Create Role",
			component: CreateRole
		},
		[`${path}corporate-profiling`]: {
			title: "Corporate Profiling",
			icon: MDAIcon,
			component: CorporateProfiling
		},
		[`${path}receipt`]: {
			title: "Receipt",
			icon: ReceiptIcon,
			component: Receipt
		},
		[`${path}receiptprt/receiptref`]: {
			title: "Generate Receipt by Payment Reference",
			icon: ReceiptIcon,
			component: ReceiptPymtRef
		},
		/*		[`${path}receipt/receiptours`]: {
			title: "Generate Receipt by Norlics Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtOurs
		},
		*/

		[`${path}receiptprt/receiptswitch`]: {
			title: "Generate Receipt by Provider Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtSwitch
		},
		[`${path}receiptprt/receiptinv`]: {
			title: "Generate Receipt by Vertical's Invoice Number",
			icon: ReceiptIcon,
			component: ReceiptPymtInv
		},
		[`${path}receiptprt/mda.receipt`]: {
			title: "Generate Mda receipt",
			icon: ReceiptIcon,
			component: Mdareceipt
		},
		[`${path}receiptprt/receipts`]: {
			title: "Generate Receipt",
			icon: ReceiptIcon,
			component: SingleInputReceipt
		},
		[`${path}settings/id-details`]: {
			title: "ID Details",
			icon: SettingsIcon,
			component: IDDetails
		},
		[`${path}settings/statetin-setup`]: {
			title: `${coy_ANSSID} Setup`,
			icon: SettingsIcon,
			component: ANSSIDSetup
		},
		[`${path}settings/monthly-salary-range`]: {
			title: "Monthly Salary Range",
			icon: SettingsIcon,
			component: MonthlySalaryRange
		},
		[`${path}settings/revenue-details`]: {
			title: "Revenue Details",
			icon: SettingsIcon,
			component: RevenueDetails
		},
		[`${path}password`]: {
			title: "Change Password",
			icon: ChangePasswordIcon,
			component: ChangePassword
		},
		[`${path}individual-details`]: {
			title: "Details",
			component: UserDetails
		},
		[`${path}PAYE/records`]: {
			title: "PAYE Records",
			component: PayeRecords
		},
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: CreatePAYEAssessment
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: CorporateView
		},
		[`${path}PAYE/Authorization`]: {
			title: "AUTHORIZATION",
			component: Auth
		},
		[`${path}PAYE/authorize-PAYE-assessment`]: {
			title: "Authorization",
			component: Authorisation
		},
		[`${path}demand-notice/consoldemandnotice`]: {
			title: "Consolidated Demand Notice",
			component: Consolidated
		},
		[`${path}demand-notice/generate-consoldemandnotice`]: {
			title: "Generate Consolidated Demand Notice",
			component: GenerateConsolidated
		},
		[`${path}demand-notice/ConsolidatedView`]: {
			title: "Consolidated Read Only",
			component: ConsolidatedView
		}
	};

	const routeBucket = isUser ? routes : adminRoutes;

	const route = routeBucket[pathname];

	const firstAllowedRoute = Object.keys(allowedRoutes || {}).find((key) =>
		Boolean(routeBucket[key])
	);

	const comp = routeBucket[firstAllowedRoute];

	useEffect(() => {
		function handleResize() {
			toggleSidebar(window.innerWidth > 768);
		}

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	function handleSidebarToggle() {
		toggleSidebar(!sidebarOpen);
	}

	return (
		<section className={styles.wrapper}>
			{sidebarOpen &&
				(isUser ? (
					<UserSidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				) : (
					<Sidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				))}
			<div className={styles.faux__sidenav} />
			<div className={styles.main__right}>
				<Header
					title={route?.title}
					icon={route?.icon && route?.icon}
					toggleSidebar={handleSidebarToggle}
				/>
				<Switch>
					<Route path={path} exact>
						{isUser ? (
							<Home />
						) : !hasDashboard && !isGovernor && comp?.component ? (
							<comp.component />
						) : isGovernor ||
						  userInfo?.account_type === "admin" ||
						  userInfo?.account_type === "Dashboard-Only" ? (
							<SpecialDashboard />
						) : (
							<Summary />
						)}
					</Route>
					{Object.keys(routeBucket).map((r) => {
						const Component = routeBucket[r]?.component;
						return (
							Component && (
								<Route key={r} path={r}>
									<Component />
								</Route>
							)
						);
					})}
				</Switch>
			</div>
		</section>
	);
};

export default Dashboard;
