import { useState } from "react";
import styles from "./style.module.css";

const PaymentListModal = ({ setModalToShow }) => {
	const [selectPaymentOption, setSelectPaymentOption] = useState("");

	const handleSelectedOption = (event) => {
		const selectedValue = event.target.value;
		setSelectPaymentOption(selectedValue);
		setModalToShow(selectedValue);
	};

	return (
		// <main className={styles.visible}>
		<div className={styles.body}>
			<div className={styles.black}>
				<div className={styles.imageText}>
					<img src="/enuguIRS50.png" alt="EsirsLogo" />
					<h3>Instant tax payment</h3>
				</div>
				<button
					className={styles.closeBtn}
					onClick={() => setModalToShow(null)}
				>
					<img src="/closeBtn.png" alt="closeBtn" />
				</button>
			</div>
			<div className={styles.white}>
				<div className={styles.selectText}>
					<h4>Select a payment method </h4>
					<span style={{ color: "red" }}>*</span>
				</div>
				<div>
					<select
						className={styles.dropdown}
						value={selectPaymentOption}
						onChange={handleSelectedOption}
					>
						<option value="">Choose a payment platform</option>
						<option value="flutterWave">FlutterWave</option>
						<option value="remita">Remita Pay</option>
						{/* <option value="interswitch">Interswitch</option> */}
						{/* <option value="transact">E-Transact</option> */}
					</select>
				</div>
			</div>
		</div>
		// </main>
	);
};

export default PaymentListModal;
