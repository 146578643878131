/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import style from "./style.module.css";
import { useState, useEffect } from "react";
import { axios } from "react";
import { RevenueHeads } from "../../../payment/components/revenueHeads";
import styles from "./style.module.css";
// import styles1 from "../Identity-management-individual/style.module.css";

const Etransacpayment = () => {
	const [BillNumber, setBillNumber] = useState("");
	// const [billNumberDetails, setBillNumberDetails] = useState(null);
	const [ownersesbn, setownersesbn] = useState("");
	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [revenue, setRevenue] = useState("");
	// const [ESBNDetails, setESBNDetails] = useState(null);
	// const [isConfirmingDetails, setIsConfirmingDetails] = useState(false);
	const [showESBN, toggleShowESBN] = useState("esbn");
	// const [loading, setLoading] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const publickey = "0PUB0469u9U51pXP507s0ks3jM22nIiR";

	// function randomReference() {
	// 	var length = 10;
	// 	var chars =
	// 		"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
	// 	var result = "";
	// 	for (var i = length; i > 0; --i)
	// 		result += chars[Math.floor(Math.random() * chars.length)];
	// 	return result;
	// }

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.surname}`);
			} else {
				setName(res.name);
			}
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => {
					if (res?.data?.data[0]?.GrandTotal) {
						setAmount(res.data.data[0].GrandTotal);
					}
				})
				.catch((error) => {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.errors?.[0] ||
								error.response.data?.msg
						});
					}
				});
		};
		if (BillNumber?.length >= 18) getBillAmount();
	}, [BillNumber]);

	const handlePost = async () => {
		const postData = {
			amount: 15000,
			currency: "NGN",
			customerFirstName: "John",
			customerLastName: "Wick",
			customerPhoneNumber: "2348032132100",
			email: "john.wick@credocentral.com"
		};

		try {
			const response = await axios.post(
				"https://api.public.credodemo.com/transaction/initialize",
				postData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `api-public key`
						// Add other headers as needed
					}
				}
			);

			console.log("Response from POST request:", response.data);
			// Handle response as needed
		} catch (error) {
			console.error("Error in POST request:", error);
			// Handle error as needed
		}
	};

	return (
		<main className={[style.main].join("")}>
			<div>
				<center>
					<div className={[style.unifiedpayment__header].join("")}>
						{/* <img
								src={UnifiedPayment}
								alt="interswitch payment"
							/> */}
					</div>
				</center>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
						padding: "0px 30px"
					}}
				>
					<div className={style.esbn__selector}>
						<input
							type="radio"
							id="esbn"
							name="esbn"
							value="esbn"
							onChange={(e) => toggleShowESBN(e.target.value)}
							checked={showESBN === "esbn"}
						/>
						<label for="esbn">Pay with ESBN</label>
					</div>

					<div className={style.esbn__selector}>
						<input
							type="radio"
							id="noEsbn"
							name="noEsbn"
							value="noEsbn"
							onChange={(e) => toggleShowESBN(e.target.value)}
							checked={showESBN === "noEsbn"}
						/>
						<label for="noEsbn">Pay without ESBN</label>
					</div>
				</div>

				<hr className={style.divider} />
				{showESBN === "esbn" && (
					<>
						<div className={styles.wrapper}>
							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<div>
									<label
										className={styles.input__label}
										htmlFor="ownersesbn"
									>
										ESBN{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										onChange={(e) =>
											setownersesbn(e.target.value)
										}
										id="ownersesbn"
										placeholder="enter your ESBN"
										required
										value={ownersesbn}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="BillNumber"
									>
										Bill Number{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										onChange={(e) =>
											setBillNumber(e.target.value)
										}
										id="BillNumber"
										placeholder="Enter bill number"
										required
										value={BillNumber}
									/>
								</div>
							</div>
							<hr className={style.divider} />

							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<div>
									<label
										className={styles.input__label}
										htmlFor="name"
									>
										Name{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										id="amount"
										placeholder="Enter Name"
										required
										disabled
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="amount"
									>
										Amount{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={amount}
										onChange={(e) =>
											setAmount(e.target.value)
										}
										id="amount"
										placeholder="Enter amount"
										required
									/>
								</div>
							</div>
							<br />
							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<RevenueHeads
									setValue={(val) => setRevenue(val)}
									value={revenue?.Description}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: "23px 30px 0px 0px"
								}}
							>
								{ownersesbn && BillNumber && name && amount && (
									<button
										onClick={() => ""}
										className={[style.btn]}
									>
										Pay now
									</button>
								)}
							</div>
						</div>
					</>
				)}
				{showESBN === "noEsbn" && (
					<>
						<div className={styles.wrapper}>
							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<div>
									<label
										className={styles.input__label}
										htmlFor="name"
									>
										Name{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
										id="name"
										placeholder="Enter Name"
										required
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="amount"
									>
										Amount{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={amount}
										onChange={(e) =>
											setAmount(e.target.value)
										}
										id="amount"
										placeholder="Enter amount"
										required
									/>
								</div>
							</div>
							<br />

							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<div>
									<label
										className={styles.input__label}
										htmlFor="email"
									>
										Email{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={email}
										onChange={(e) =>
											setEmail(e.target.value)
										}
										id="name"
										placeholder="Enter Email"
										required
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="phone"
									>
										Phone Number{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										value={phone}
										onChange={(e) =>
											setPhone(e.target.value)
										}
										id="amount"
										placeholder="Enter phone number"
										required
									/>
								</div>
							</div>

							<br />

							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<RevenueHeads
									setValue={(val) => setRevenue(val)}
									value={revenue}
								/>
							</div>

							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: "23px 30px 0px 0px"
								}}
							>
								{name &&
									amount &&
									email &&
									phone &&
									revenue && (
										<button
											className={[style.btn]}
											onClick={() => handlePost()}
										>
											Pay now
										</button>
									)}
							</div>
						</div>
					</>
				)}
			</div>
		</main>
	);
};

export default Etransacpayment;
