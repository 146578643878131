import React from "react";
import style from "./style.module.css";
import Unifiedpayment from "./unifiedpayment";

const Unifiedpage = () => {
	return (
		<div className={[style.row]}>
			<Unifiedpayment />
		</div>
	);
};

export default Unifiedpage;
