import {
	Switch,
	BrowserRouter as Router,
	Route,
	Redirect
} from "react-router-dom";
import Login from "./Auth/Login/Login";
import Signup from "./Auth/SignUp/signup";
import ResetPassword from "./Auth/ResetPassword";
import IndividualIdentityManagement from "./Identity-management-individual";
import CorporateIdentityManagement from "./Identity-management-corporate";
import CorporateIdentityManagementSuccess from "./Identity-management-corporate/final";
import IndividualIdentityManagementSuccess from "./Identity-management-individual/final";
import Otp from "./Auth/OTP";
import Dashboard from "./Dashboard";
import Home from "./Home";
import InterSwitch from "./payment";
import Unifiedpayment from "./payment/unifiedpayment";
import Flutterwave from "./payment/flutterwave";
import Paymentpage from "./payment/paymentpage";
import Flutterwavepage from "./payment/flutterwavepage";
import Interswitchpage from "./payment/interswitchpage";
import RemitaCheckout from "./payment/remita";
import Unifiedpage from "./payment/unifiedpaymentpage";
import Etranscpage from "./payment/etransacpage";
import Paymentsuccessful from "./payment/paymentsuccessful";
import TaxType from "./Home/tax-type";
import TaxSystem from "./Home/tax-system";
import TaxCollection from "./Home/tax-collection";
import TaxLegislation from "./Home/tax-legislation";
import AboutUs from "./Home/about-us";
import FaqPage from "./Home/faqPage";
import ConsolidatedSteps from "./Home/consolidated-steps";
import NewsPage from "./Home/newsPage";
import MoniePointPage from "./payment/moniepointpage";
function App() {
	const accessToken = localStorage.getItem("access_token");
	let userInfo = localStorage.getItem("user_info");
	if (userInfo) {
		userInfo = JSON.parse(userInfo);
	}

	return (
		<Router>
			<Switch>
				<Route path="/home">
					<Home />
				</Route>
				<Route path="/tax-type">
					<TaxType />
				</Route>
				<Route path="/tax-system">
					<TaxSystem />
				</Route>
				<Route path="/tax-collection">
					<TaxCollection />
				</Route>
				<Route path="/tax-legislation">
					<TaxLegislation />
				</Route>
				<Route path="/about-us">
					<AboutUs />
				</Route>
				<Route path="/frequently-asked-questions">
					<FaqPage />
				</Route>
				<Route path="/latest-news">
					<NewsPage />
				</Route>
				<Route path="/steps-to-pay-bills">
					<ConsolidatedSteps />
				</Route>

				<Route path="/identity-management">
					{userInfo?.account_type === "corporate" ? (
						<CorporateIdentityManagement />
					) : (
						<IndividualIdentityManagement />
					)}
				</Route>
				<Route path="/CIM-success">
					<CorporateIdentityManagementSuccess />
				</Route>
				<Route path="/IM-success">
					<IndividualIdentityManagementSuccess />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				{/* <Route path="/addnew">
					<Addnew />
				</Route> */}
				{/* Interswitch payment gate */}
				<Route path="/payment">
					<InterSwitch />
				</Route>
				{/* UP payment gate */}
				<Route path="/unifiedpayment">
					<Unifiedpayment />
				</Route>
				<Route path="/paymentsuccessful">
					<Paymentsuccessful />
				</Route>
				<Route path="/unifiedpaymentpage">
					<Unifiedpage />
				</Route>
				{/* flutterwave payment gate */}
				<Route path="/flutterwave">
					<Flutterwave />
				</Route>
				{/* Payment page with options */}
				<Route path="/paymentpage">
					<Paymentpage />
				</Route>
				{/* Flutterwave page with options */}
				<Route path="/flutterwavepage">
					<Flutterwavepage />
				</Route>
				<Route path="/interswitchpage">
					<Interswitchpage />
				</Route>
				<Route path="/moniepointpage">
					<MoniePointPage />
				</Route>
				<Route path="/remitapage">
					<RemitaCheckout />
				</Route>
				<Route path="/etransacpage">
					<Etranscpage />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/signup">
					<Signup />
				</Route>
				<Route path="/otp-phone">
					<Otp title="Phone" />
				</Route>
				<Route path="/otp-email">
					<Otp title="Email" />
				</Route>
				<Route path="/individual-identity-management">
					{!/^(individual)|(corporate)$/i.test(
						userInfo?.account_type
					) ? (
						<IndividualIdentityManagement />
					) : (
						<div>You should not be here...</div>
					)}
				</Route>
				<Route path="/corporate-identity-management">
					<CorporateIdentityManagement />
				</Route>
				<Route path="/">
					{(userInfo?.account_type &&
						!/^(individual)|(corporate)$/i.test(
							userInfo?.account_type
						)) ||
					userInfo?.anssid ? (
						<Dashboard />
					) : accessToken ? (
						<Redirect to="/identity-management" />
					) : (
						<Redirect to="/home" />
					)}
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
