/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Spinner from "../../../components/spinner";
// import styles from "./style.module.css";
import styles from "../../../Identity-management-individual/style.module.css";

// import styles2 from "../MDAs/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Table from "../../../components/Table";
import { useDebounce } from "../hooks";
import Alert from "../../../components/Alert";
import Receipt from "../../ReceiptPymtRef/receipt";

const user = JSON.parse(localStorage.getItem("user_info"));

const EditBill = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [ownersesbn, setownersesbn] = useState("");
  const [BillNumber, setBillNumber] = useState("");
  const [ownersname, setownersname] = useState("");
  const [owneremail, setowneremail] = useState("");
  const [ownersphone, setownersphone] = useState("");
  const [ownersaddr, setownersaddr] = useState("");
  const [ownersaddrcity, setownersaddrcity] = useState("");
  const [accountType, setAccountType] = useState("individual");
  const [payername, setpayername] = useState("");
  const [propertyaddr, setpropertyaddr] = useState("");
  const [propertycity, setpropertycity] = useState("");
  const [appartmentno, setappartmentno] = useState("");
  const [ownershiptype, setownershiptype] = useState("");
  const [profile, setprofile] = useState("");
  const [profilecategory, setprofilecategory] = useState("");
  const [tagId, settagId] = useState("");
  const [billtype, setbilltype] = useState("");
  const [invoice, setInvoice] = useState("");

  const [authoriser, setauthoriser] = useState("");
  const [phoneno, setPhoneNumber] = useState("");
  const [assesscode, setAssessmentCode] = useState("");
  const [email, setEmail] = useState("");
  const [residentialaddress, setResidentialaddress] = useState("");
  const [RegistrationFee, setRegistrationFee] = useState("");
  const [RenewalFee, setRenewalFee] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountReason, setDiscountReason] = useState("");
  const [outstand, setOutStanding] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filterSubCategories, setFilteredSubCategories] = useState([]);
  const [currentBillType, setCurrentBillType] = useState("");
  const [subCatData, setSubCatData] = useState({ value: "", index: null });
  const [currentPropertyID, setCurrentPropertyID] = useState({ value: "", index: null });
  const [landMeasurement, setLandMeasurement] = useState({ value: "", index: null });
  const [mdaProperties, setMDAProperties] = useState({})
  const [areas, setAreas] = useState([]);
  const [zones, setZones] = useState([]);
  const [lgas, setLGAs] = useState([]);
  const [allBillsFromServer, setAllBillsFromServer] = useState([]);
  const [alert, setAlert] = useState({
    showing: false,
    type: null,
    message: ""
  });
  const [profileDetailsArray, setProfileDetailsArray] = React.useState([
    {
      amount: "",
      houseNo: "",
      HouseStr: "",
      houseArea: "",
      houseLGA: "",
      houseZone: "",
      revenuedescription: "",
      profile: "",
      profilecategory: "",
      billtype: "",
      propertyid: "",
      landmeasure: ""
    },
  ]);
  const [profileDetails, setProfileDetails] = React.useState({
    amount: "",
    houseNo: "",
    HouseStr: "",
    houseArea: "",
    houseLGA: "",
    houseZone: "",
    revenuedescription: "",
    profile: "",
    profilecategory: "",
    billtype: "",
    propertyid: "",
    landmeasure: ""
  });

  let { state } = useLocation();

  const handleDiscount = (value) => {
    if (allBilledAmount < value) {
      setAlert({
        showing: true,
        type: 'error',
        message: "Discount cannot be higher than amount."
      })
    } else {
      setDiscount(value);
      setAlert({
        showing: false,
        type: null,
        message: ""
      })
    }
  }

  const allBilledAmount = useMemo(
    () => {
      const allAmount = profileDetailsArray.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.amount),
        0
      )
      const newTotalAmount = Number(allAmount) > 0 && Number(allAmount) - Number(discount) + Number(outstand) >= 0 ?
        Number(allAmount) - Number(discount) + Number(outstand) : Number(allAmount);
      return newTotalAmount
    },
    [profileDetailsArray, outstand, discount, billtype]
  );

  const getSelectedCategories = (e) => {
    const value = e.target.value;
    const currentCategory = categories.find((singleCategory) => (
      singleCategory.Description === value
    ));
    const filteredSubCategory = subCategories.filter(
      (category) => {
        return category.CategoryCode === currentCategory?.Code && category.Coy === currentCategory?.Coy
      });

    console.log({ currentCategory, subCategories, filteredSubCategory })
    setFilteredSubCategories(filteredSubCategory);
  };

  const getAmount = useMemo(() => {
    const [RegistrationFee, RenewalFee, FixedFee, i] =
      subCatData.value.split("/");
    console.log({ RegistrationFee, RenewalFee, FixedFee })

    const allAvailableBills = { RegistrationFee, RenewalFee, FixedFee };

    if (
      allAvailableBills[currentBillType] &&
      Number(allAvailableBills[currentBillType]) > 0
    ) {
      setError("");
      return allAvailableBills[currentBillType];
    } else {
      currentBillType && subCatData.value && setError("Tarrif doesn't exist for the sub-category");
      return "";
    }
  }, [subCatData, currentBillType]);

  const updateAmount = (value, i) => {
    const newProfilesArray = profileDetailsArray.map((child, index) => {
      return index === Number(i) ? { ...child, amount: value } : child;
    });
    setProfileDetailsArray(newProfilesArray);
  };

  const handLandMeasurement = (amount, i) => {
    if (landMeasurement.index >= 0) {
      if (landMeasurement.index === i) {
        const landMeasurementAmount = landMeasurement.value * amount
        updateAmount(landMeasurementAmount, i);
      } else {
        updateAmount(amount, i);
      }
    } else {
      updateAmount(amount, i);
    }
  }

  useEffect(() => {
    const i = subCatData.index;

    handLandMeasurement(getAmount, i);
  }, [getAmount, subCatData, landMeasurement]);

  const handleProfileInArrayChange = (e, i) => {
    const value = e.target.value;
    const name = e.target.name;

    const newProfilesArray = profileDetailsArray.map((child, index) => {
      return index === i ? { ...child, [name]: value } : child;
    });

    setProfileDetailsArray(newProfilesArray);
  };

  const addAnotherProfile = () => {
    setProfileDetailsArray([...profileDetailsArray, profileDetails]);
    setSubCatData(({ value: "", index: null }));
    setCurrentBillType("");
    setProfileDetails({
      ...profileDetails,
      amount: "",
      houseNo: "",
      HouseStr: "",
      houseArea: "",
      houseLGA: "",
      houseZone: "",
      revenuedescription: "",
      profile: "",
      profilecategory: "",
      billtype: "",
      propertyid: "",
      landmeasure: ""
    });
  };

  function removeChild(index) {
    const newChildrenArray = [...profileDetailsArray];
    newChildrenArray.splice(index, 1);
    setProfileDetailsArray(newChildrenArray);
  }

  const fetchAllBillsFromServer = useCallback(async () => {
    const res = await axios
      .get(`billing/${state.coy}/${state.userId}`)
      .then((res) => (res.data || {}).data?.result);
    setownersesbn(res?.OwnersESBN)
    setownersname(res?.OwnersName ?? res.PayerName)
    setResidentialaddress(res?.OwnersAddr)
    setPhoneNumber(res?.OwnersPhone)
    setEmail(res?.OwnerEmail)
    setDiscount(res?.Discount)
    setOutStanding(res?.Outstanding)
    const newTaxPayerBillsDetails = res?.TaxPayerBillsDetails?.map((detail) => (
      {
        amount: detail?.Amount,
        houseNo: detail?.HouseNo,
        // BillNumber,
        billtype: detail?.BillType,
        coy: detail?.Coy,
        deactivated: detail?.Deactivated,
        houseArea: detail?.HouseArea,
        houseLGA: detail?.HouseLGA,
        HouseStr: detail?.HouseStreet,
        houseZone: detail?.HouseZone,
        profile: detail?.Profile,
        profilecategory: detail?.ProfileCategory,
        propertyid: detail?.PropertyId,
        revenuedescription: detail?.RevenueDescription,
        landmeasure: detail?.LandMeasurement
        // TaxPayerBillBillNumber,
      }
    ))
    setProfileDetailsArray(newTaxPayerBillsDetails);
    setAllBillsFromServer(res);
  }, [state.userId, state.coy])

  useEffect(() => {
    fetchAllBillsFromServer();
  }, [fetchAllBillsFromServer]);


  // console.log({ allBillsFromServer })
  useEffect(() => {
    const getESBN = async () => {
      const res = await axios
        .get(`users/anssid?anssid=${ownersesbn}`)
        .then((res) => (res.data || {}).data);

      setAccountType(res.accountType);
      setownersname(`${res.firstname} ${res.surname}`);
      setResidentialaddress(res.residentialaddress);
      setPhoneNumber(res.phonenumber);
      setEmail(res.email);
    };
    if (ownersesbn?.length >= 10) getESBN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownersesbn]);

  useEffect(() => {
    const fetchCategories = async () => {
      const res = await axios
        .get(`settings/profilecategorys/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);
      setCategories(res);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      const res = await axios
        .get(`settings/tarrifcommerces/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);
      setSubCategories(res);
    };
    fetchSubCategories();
  }, []);

  useEffect(() => {
    console.log('we don fetch them')
  }, [categories, subCategories])

  // Area
  useEffect(() => {
    const fetchArea = async () => {
      const res = await axios
        .get(`settings/areas/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);
      setAreas(res);
    };
    fetchArea();
  }, []);

  // Zone
  useEffect(() => {
    const fetchZones = async () => {
      const res = await axios
        .get(`settings/zones/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);
      setZones(res);
    };
    fetchZones();
  }, []);

  // LGA
  useEffect(() => {
    const fetchLGAs = async () => {
      const res = await axios
        .get(`settings/lgass/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);
      setLGAs(res);
    };
    fetchLGAs();
  }, []);

  useEffect(() => {
    const fetchPropId = async (propId, i) => {
      const res = await axios
        .get(`mdapropertyprofile/${propId}/${user?.mdaModuleCoy}`)
        .then((res) => (res.data || {}).data);

      const newProfilesArray = profileDetailsArray.map((child, index) => {
        return index === Number(i) ? {
          ...child,
          houseNo: res.AppartmentNo,
          HouseStr: res.PropertyAddr,
          houseArea: "",
          houseLGA: "",
          houseZone: ""
        } : child;
      });
      setProfileDetailsArray(newProfilesArray);
      setMDAProperties(res);
    };
    if (!!currentPropertyID.value && currentPropertyID.index !== null) {
      fetchPropId(currentPropertyID.value, currentPropertyID.index);
    }
  }, [currentPropertyID]);


  const generateAssessmentCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios.get(`gettmsids/${user?.mdaModuleCoy}`)
      .then((res) => {
        console.log(res.data, 'genAssCode')
        if (res.data.Id) {
          submit(res.data.Id);
        }
      })
      .catch((error) => {
        console.log({ error })
      })
  }

  const submit = async (code) => {
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("access_token");
      await axios
        .put(
          `/billing/${user?.mdaModuleCoy}/${code}`,
          {
            coyid: allBillsFromServer?.CoyId,
            billnumber: allBillsFromServer.BillNumber ? `${allBillsFromServer.BillNumber}`.trim() : '',
            owneremail: email ? `${email}`.trim() : '',
            ownersesbn: ownersesbn ? `${ownersesbn}`.trim() : '',
            ownersname: !!ownersesbn ?
              ownersname ?
                `${ownersname}`.trim() : ''
              : '',
            ownersaddr: residentialaddress ? `${residentialaddress}`.trim() : '',
            ownersaddrcity: mdaProperties?.OwnersAddrCity ? `${mdaProperties?.OwnersAddrCity}`.trim() : 'Enugu',
            ownersphone: phoneno ? `${phoneno}`.trim() : '',
            payername: !ownersesbn ?
              ownersname ?
                `${ownersname}`.trim() : ''
              : '',
            propertyaddr: mdaProperties?.PropertyAddr ? `${mdaProperties?.PropertyAddr}`.trim() : '',
            propertycity: mdaProperties?.PropertyCity ? `${mdaProperties?.PropertyCity}`.trim() : 'Enugu',
            propertyid: '',
            ownershiptype: allBillsFromServer?.OwnershipType ? `${allBillsFromServer?.OwnershipType}`.trim() : ' ',
            tagId: mdaProperties?.TagId ? `${mdaProperties?.TagId}`.trim() : '',
            authoriser: mdaProperties?.Authoriser ? `${mdaProperties?.Authoriser}`.trim() : '',
            totamt: allBilledAmount ? `${allBilledAmount}`.trim() : '',
            discount: discount ? `${discount}`.trim() : '',
            discountreason: discountReason ? `${discountReason}`.trim() : '',
            grandtot: allBilledAmount ? `${allBilledAmount}`.trim() : '',
            outstand: outstand ? `${outstand}`.trim() : '',
            billDetails: profileDetailsArray?.map((detail) => ({
              ...detail,
              coy: user?.mdaModuleCoy ? `${user?.mdaModuleCoy}`.trim() : '',
              profile: `${detail.profile.split('/')[2]}`,
              profilecategory: `${detail.profilecategory.split('+')[0]}`.trim(),
              landmeasure: detail?.landmeasure ? `${detail.landmeasure}`.trim() : '',
              deactivated: false,
            })),
            inputter: mdaProperties?.Inputter ? `${mdaProperties?.Inputter}`.trim() : '',
            deactivated: false,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((res) => res.data);

      setBillNumber("");
      setownersesbn("");
      setownersname("");
      setownersaddr("");
      setownersaddrcity("");
      setownersphone("");
      setEmail("");
      setowneremail("");
      setpayername("");
      setpropertyaddr("");
      setpropertycity("");
      setownershiptype("");
      setappartmentno("");
      settagId("");
      setprofilecategory("");
      setprofile("");
      setauthoriser("");
      setbilltype("");
      setRegistrationFee("");
      setRenewalFee("");
    } catch (err) {
      console.error(err);
      setError(
        "MDA could not be created. Please verify your details and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  console.log({ profileDetailsArray, allBillsFromServer })
  return (
    <>
      <form className={styles.form} onSubmit={generateAssessmentCode}>
        {alert.showing && (
          <Alert text={alert.message} type={alert.type} />
        )}

        <header>
          <h2>Taxpayer bill</h2>
        </header>
        <hr className={styles.divider} />
        <div className={styles.double__inputs}>
          <div>
            <label className={styles.input__label} htmlFor="ownersesbn">
              Esbn
            </label>
            <input
              type="text"
              onChange={(e) => setownersesbn(e.target.value)}
              id="ownersesbn"
              placeholder=""
              value={ownersesbn}
            />
          </div>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="ownersname">
              Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              onChange={(e) => setownersname(e.target.value)}
              id="ownersname"
              placeholder=""
              required
              //  disabled
              value={ownersname}
            />
          </div>
        </div>
        <div className={styles.double__inputs}>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="residentialaddress">
              Address
            </label>
            <input
              type="text"
              onChange={(e) => setResidentialaddress(e.target.value)}
              id="residentialaddress"
              placeholder=""
              //  disabled
              value={residentialaddress}
            />
          </div>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="phonenumber">
              Phone
            </label>
            <input
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
              id="phonenumber"
              placeholder=""
              //  disabled
              value={phoneno}
            />
          </div>
        </div>
        <div className={styles.double__inputs}>

          <div>
            <br />
            <label className={styles.input__label} htmlFor="email">
              Email
            </label>
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder=""
              //  disabled
              value={email}
            />
          </div>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="discount">
              Discount
            </label>
            <input
              type="text"
              onChange={(e) => handleDiscount(e.target.value)}
              id="discount"
              placeholder=""
              value={discount}
            />
          </div>
        </div>
        <div className={styles.double__inputs}>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="discountReason">
              Discount reason
            </label>
            <input
              type="text"
              onChange={(e) => setDiscountReason(e.target.value)}
              id="discountReason"
              placeholder=""
              value={discountReason}
            />
          </div>
          <div>
            <br />
            <label className={styles.input__label} htmlFor="outstanding">
              Outstanding
            </label>
            <input
              type="text"
              onChange={(e) => setOutStanding(e.target.value)}
              id="outstanding"
              placeholder=""
              value={outstand}
            />
          </div>
        </div>
        <hr className={styles.divider} />

        {/* Profile ID details */}
        {profileDetailsArray.map((child, index) => (
          <React.Fragment key={`profile${index}`}>
            <div className={styles.double__inputs}>
              <div>
                <label
                  className={styles.input__label}
                  htmlFor="revenuedescription"
                >
                  Property ID
                </label>
                <input
                  type="text"
                  name="propertyid"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                    setCurrentPropertyID({ value: e.target.value, index });
                  }}
                  id="propertyid"
                  placeholder=""
                  value={child.propertyid}
                />
              </div>
            </div>
            <br />
            <h3>Address</h3>
            <div className={styles.double__inputs}>
              <div>
                <label className={styles.input__label} htmlFor="houseNo">
                  No
                </label>
                <input
                  type="text"
                  name="houseNo"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                  }}
                  id="houseNo"
                  placeholder=""
                  //  disabled
                  value={child.houseNo}
                />
              </div>
              <div>
                <label className={styles.input__label} htmlFor="HouseStr">
                  Street
                </label>
                <input
                  type="text"
                  name="HouseStr"
                  onChange={(e) => handleProfileInArrayChange(e, index)}
                  id="HouseStr"
                  placeholder=""
                  //  disabled
                  value={child.HouseStr}
                />
              </div>
            </div>

            <br />
            <div className={styles.double__inputs}>
              <div>
                <label className={styles.input__label} htmlFor="houseLGA">
                  LGA
                </label>
                <select
                  id="houseLGA"
                  className={styles.input__long}
                  name="houseLGA"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                  }}
                  value={child.houseLGA}
                  required
                >
                  <option>--Select LGA--</option>
                  {lgas?.map((lga, index) => (
                    <React.Fragment key={`${lga.Description}${index}`}>
                      <option value={lga.Description}>{lga.Description}</option>
                    </React.Fragment>
                  ))}
                </select>
              </div>
              <div>
                <label className={styles.input__label} htmlFor="houseZone">
                  Zone
                </label>
                <select
                  id="houseZone"
                  className={styles.input__long}
                  name="houseZone"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                  }}
                  value={child.houseZone}
                  required
                >
                  <option>--Select Zone--</option>
                  {zones?.map((zone, index) => (
                    <React.Fragment key={`${zone.Description}${index}`}>
                      <option value={zone.Description}>{zone.Description}</option>
                    </React.Fragment>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.double__inputs}>
              <div>
                <label
                  className={styles.input__label}
                  htmlFor="revenuedescription"
                >
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="revenuedescription"
                  onChange={(e) => handleProfileInArrayChange(e, index)}
                  id="revenuedescription"
                  placeholder=""
                  required
                  //  disabled
                  value={child.revenuedescription}
                />
              </div>
            </div>

            <br />
            <br />
            <div className={styles.select__wrapper}>
              <div>
                <label htmlFor="billtype">
                  Bill Type <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="billtype"
                  className={styles.input__medium}
                  name="billtype"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                    setCurrentBillType(e.target.value);
                  }}
                  value={child.billtype}
                  required
                >
                  <option>--Select BillType--</option>
                  <option value={"RenewalFee"}>Renewal</option>
                  <option value={"RegistrationFee"}>Registration</option>
                  <option value={"FixedFee"}>Fixed Amount</option>
                </select>
              </div>
            </div>
            <div className={styles.select__wrapper}>
              <div>
                <label htmlFor="profile">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="profile"
                  className={styles.input__medium}
                  name="profile"
                  onChange={(e) => {
                    handleProfileInArrayChange(e, index);
                    getSelectedCategories(e);
                  }}
                  value={child?.profile ?? ''}
                  required
                >
                  <option>--Select Category--</option>
                  {categories.length &&
                    categories?.map((category, index) => (
                      <React.Fragment key={`${category?.Coy}${index}`}>
                        <option value={`${category?.Description}`}>
                          {category?.Description ?? ''}
                        </option>
                      </React.Fragment>
                    ))}
                </select>
              </div>

              <div>
                <label htmlFor="profilecategory">
                  Sub-Category <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="profilecategory"
                  className={styles.input__medium}
                  name="profilecategory"
                  onChange={(e) => {
                    setSubCatData({ value: e.target.value.split('+')[1], index });
                    handleProfileInArrayChange(e, index);
                  }}
                  value={child.profilecategory}
                  required
                >
                  <option>--Select Sub-Category--</option>
                  {filterSubCategories.length && filterSubCategories?.map((sub, index) => (
                    <React.Fragment key={sub?.id + index}>
                      <option
                        value={
                          sub?.Description + "+" +
                          sub?.RegistrationFee +
                          "/" +
                          sub?.RenewalFee +
                          "/" +
                          sub?.FixedFee +
                          "/" +
                          index
                        }
                      >
                        {sub?.Description}
                      </option>
                    </React.Fragment>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.double__inputs}>
              <div>
                <label className={styles.input__label} htmlFor="amount">
                  Amount <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="amount"
                  onChange={(e) => handleProfileInArrayChange(e, index)}
                  id="amount"
                  required
                  value={child.amount}
                />
              </div>
            </div>
            <br />
            {profileDetailsArray.length > 1 && (
              <button
                type="button"
                className={styles.add__another__child__btn}
                style={{
                  color: "#F0372E",
                  textAlign: "left",
                  padding: 0,
                  marginBottom: 10,
                  marginTop: 10,
                }}
                onClick={() => {
                  removeChild(index);
                }}
              >
                - Remove Property {index + 1}
              </button>
            )}
            <hr className={styles.divider} />
          </React.Fragment>
        ))}

        {/* <hr className={styles.divider} /> */}
        <button
          type="button"
          className={styles.add__another__child__btn}
          onClick={addAnotherProfile}
        >
          + Add another property
        </button>
        <hr className={styles.divider} />
        <div className={styles.double__inputs}>
          <h4 style={{ fontWeight: "bold" }}>Total Amount Billed: </h4>&nbsp;
          <h4 style={{ fontWeight: 300 }}>&#8358;{allBilledAmount}</h4>
        </div>
        <div className={styles.double__inputs}>
          <h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
          <h4 style={{ fontWeight: 300 }}>&#8358;{allBilledAmount}
          </h4>
        </div>

        <hr className={styles.divider} />
        {/* total and stuffs */}
        <footer className={styles.form__footer}>
          <button
            disabled={loading}
            type="submit"
            className={[
              "primary__btn",
              styles.primary__button,
              loading ? styles.loading__button : "",
            ].join(" ")}
          >
            Generate bill
            {loading && <Spinner />}
          </button>
        </footer>
      </form>
      {!!invoice && <Receipt data={invoice} loading={loading} />}
    </>
  )
}

export default EditBill