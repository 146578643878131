import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../style.module.css";

export const Mdas = ({ setValue = () => {}, value }) => {
	const location = useLocation();
	const [getmdas, setMdas] = useState([]);

	const Mdas = async () => {
		const res = await axios
			.get(`/settings/allmdas`)
			.then((res) => (res.data || {}).data);
		setMdas(res);
	};

	useEffect(() => {
		Mdas();
	}, []);

	useEffect(() => {
		if (getmdas.length > 0 && location.pathname === "/MyPaye-Record") {
			setValue("20008001");
		}
		// eslint-disable-next-line
	}, [getmdas]);

	return (
		<div>
			<label className={styles.input__label} htmlFor="mda">
				MDAS <span style={{ color: "red" }}>*</span>
			</label>
			<select
				id="mda"
				className={styles.input__medium}
				name="mda"
				onChange={(e) => {
					setValue(e.target.value);
				}}
				value={value}
				disabled={location.pathname === "/MyPaye-Record"}
				required
			>
				<option value="">
					{getmdas ? "--Select MDA --" : "Loading..."}
				</option>
				{getmdas &&
					getmdas.map((getMdas) => (
						<option value={getMdas.Code} key={getMdas.Code}>
							{getMdas.Description}
						</option>
					))}
			</select>
		</div>
	);
};
