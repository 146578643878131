import React from "react";
import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { formatAmount, formatDate } from "../../utils";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import CreateTaxPayer from "./create";
import homeStyle from "./style.module.css";
import styles from "../MDAs/style.module.css";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import Alert from "../../components/Alert";
import RegularModal from "../../components/Modal/regular";
import MDAstyles from "../../Dashboard/MDAs/style.module.css";
import { exportExcel, remapObj } from "../../utils";

const user = JSON.parse(localStorage.getItem("user_info"));

const Profile = () => {
	const history = useHistory();

	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [OwnersESBN, setOwnersESBN] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [ownersname, setownersname] = useState("");
	// const [OwnerEmail, setOwnerEmail] = useState("");
	// const [OwnersPhone, setOwnersPhone] = useState("");
	const [Inputter, setInputter] = useState("");
	const [PropertyId, setPropertyId] = useState("");
	const [houseNo, setHouseNo] = useState("");
	const [houseStreet, setHouseStreet] = useState("");
	const [taxZone, setTaxZone] = useState("");
	const [allTaxZones, setAllTaxZones] = useState([]);
	const [taxLGA, setTaxLGA] = useState("");
	const [allTaxLGAs, setAllTaxLGAs] = useState([]);
	const [taxOffice, setTaxOffice] = useState("");
	const [allTaxOffices, setAllTaxOffices] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const [billsToPrint, setBillsToPrint] = useState([]);
	const [readyToPrintAll, setReadyToPrintAll] = useState(false);
	const [billNumbersToPrint, setBillNumbersToPrint] = useState([]);
	const [indexOfBillToRemove, setIndexOfBillToRemove] = useState(null);
	const [removingBill, setRemovingBill] = useState(false);
	const [isGettingReady, setIsGettingReady] = useState(false);
	const filterRef = createRef();

	const [revokeModalStatus, setRevokeModalStatus] = useState({
		revoking: false,
		showing: false,
		data: null
	});
	const [billNoOfAmountPaidShowing, setBillNoOfAmountPaidShowing] =
		useState("");
	const [amountPaid, setAmountPaid] = useState("");

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let templateStr = `
<html>
<head>
    <style>
        /* Style for regular display */

        #print_root {
            width: 100%;
            height: 50%;
            /* Adjust height as needed */
        }

        /* Style for printing */
        @media print {
            @page {
                size: landscape;
            }

            #print_root {
                page-break-after: always;
                // height: 100px;
            }
        }
    </style>
</head>

<body>
        {PRINT_STR}
</body>
</html>
`;

	const headers = [
		["ESBN", "OwnersESBN"],
		["Bill Number", "BillNumber"],
		["Property ID", "PropertyId"],
		["Name", "OwnersName"],
		// ["Email", "OwnerEmail"],
		// ["Phone", "OwnersPhone"],
		["Current Bill Amt", "CurrentBillAmt", formatAmount],
		["Out. Bill Amt", "OutStandingBillAmt", formatAmount],
		["Total Amt Paid", "TotalAmtPaid", formatAmount],
		["Tax Zone", "TaxZone"],
		["Tax LGA", "TaxLGA"],
		["Tax Office", "TaxOffice"],
		["House No", "HouseNo"],
		["House Street", "HouseStreet"],
		["Created At", "createdAt", formatDate],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"],
		["Inputter", "Inputter"]
	];

	const getProfile =
		({
			currentPage = 1,
			pageSize = 20,
			download = false,
			printing = false
		} = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef?.current?.classList?.contains(styles.no__display)) {
				filterRef?.current?.classList?.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;
			if (ownersname) {
				query += `Name=${ownersname}&`;
			}

			if (OwnersESBN) {
				query += `ESBN=${OwnersESBN}&`;
			}

			// if (OwnerEmail) {
			// 	query += `email=${OwnerEmail}&`;
			// }

			// if (OwnersPhone) {
			// 	query += `phone=${OwnersPhone}&`;
			// }

			if (BillNumber) {
				query += `BillNo=${BillNumber}&`;
			}

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (Inputter) {
				query += `Inputter=${Inputter}&`;
			}

			if (PropertyId) {
				query += `propertyId=${PropertyId}&`;
			}

			if (houseNo) {
				query += `houseNo=${houseNo}&`;
			}

			if (houseStreet) {
				query += `houseStreet=${houseStreet}&`;
			}

			if (taxOffice) {
				query += `taxOffice=${taxOffice}&`;
			}

			if (taxZone) {
				query += `TaxZone=${taxZone}&`;
			}

			if (taxLGA) {
				query += `TaxLGA=${taxLGA}&`;
			}

			if (paymentStatus) {
				query += `paymentStatus=${paymentStatus}&`;
			}

			if (processStatus) {
				query += `processStatus=${processStatus}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.get(
						`mdareports/mdabilldisplay/${user?.mdaModuleCoy}?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });
				if (!data || !data?.data) {
					// handle errors
					return null;
				}

				if (!download && !printing) setData(data.data);
				setTotal(data?.total);

				return data.data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message ||
							error.response.data.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const revoke = async () => {
		setRevokeModalStatus((revokeStatus) => ({
			...revokeStatus,
			revoking: true
		}));
		try {
			const { msg } = await axios
				.put("tmsprocess", {
					billCode: revokeModalStatus?.data?.BillNumber,
					processUpd: "3",
					MDA_Flg: "02",
					coy: revokeModalStatus?.data?.CoyId,
					userid: user?.email
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: msg
			});

			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const getAllTaxOffices = async () => {
		try {
			const offices = await axios
				.get(`settings/assesstaxoffice`)
				.then((res) => res.data);

			setAllTaxOffices(offices.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const getAllTaxLGAs = async () => {
		try {
			const LGAs = await axios
				.get(`settings/lgass/01`)
				.then((res) => res.data);

			setAllTaxLGAs(LGAs.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const getAllTaxZones = async () => {
		try {
			const zones = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => res.data);

			setAllTaxZones(zones.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		} finally {
			setRevokeModalStatus({
				revoking: false,
				showing: false,
				data: null
			});
		}
	};

	const getAmountPaid = async (billNumber) => {
		try {
			const amountPaid = await axios
				.get(`account/bill/payment/${billNumber}`)
				.then((res) => res.data.data);

			setAmountPaid(amountPaid);
		} catch (error) {
			setAmountPaid("");
			setBillNoOfAmountPaidShowing("");
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const clearFilters = () => {
		setOwnersESBN("");
		setStartDate("");
		setEndDate("");
		setownersname("");
		// setOwnerEmail("");
		// setOwnersPhone("");
		setBillNumber("");
		setInputter("");
		setPropertyId("");
		setHouseNo("");
		setHouseStreet("");
		setTaxOffice("");
		setTaxLGA("");
		setTaxZone("");
		setProcessStatus("");
		setPaymentStatus("");
		setMinAmount("");
		setMaxAmount("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getProfile()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getProfile()();
		getAllTaxLGAs();
		getAllTaxZones();
		getAllTaxOffices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const exportAnalytics = async () => {
		const info = await getProfile({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		exportExcel(remapObj(info, headers), headers, `MDA Report.xlsx`);
	};

	const toggleAddToPrint = async (billNumber) => {
		try {
			const bill = await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}`)
				.then((res) => res.data || { data: [] });

			setBillNumbersToPrint((billNos) => [...billNos, billNumber]);
			setBillsToPrint((bills) => [...bills, bill]);
			setBillsToPrint((bills) => [...bills, "\n \n \n \n \n \n"]);
		} catch (error) {
			console.error(error);
		}
	};

	const removeFromPrint = (billNumber) => {
		setBillNumbersToPrint((billNos) =>
			billNos.filter((number, i) => {
				if (number === billNumber) setIndexOfBillToRemove(i * 2);
				setRemovingBill(true);
				return number !== billNumber;
			})
		);
	};

	const removeFromBillsToPrint = () => {
		setBillsToPrint((bills) =>
			bills.filter((bill, i) => i !== indexOfBillToRemove)
		);
		setBillsToPrint((bills) =>
			bills.filter((bill, i) => i !== indexOfBillToRemove)
		);
		setRemovingBill(false);
	};

	useEffect(() => {
		if (removingBill) {
			removeFromBillsToPrint();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removingBill]);

	const handleBulkPrinting = (e) => {
		e?.preventDefault();

		if (!!billsToPrint) {
			templateStr = templateStr.replace(
				"{PRINT_STR}",
				billsToPrint.join("\b")
			);
			const w = window.open();
			w.document.write(templateStr);
			w.document.close();
			w.onload = () => {
				w.focus();
				w.print();
			};

			w.onafterprint = () => {
				w.close();
				setBillNumbersToPrint([]);
				setBillsToPrint([]);
				setReadyToPrintAll(false);
			};
		} else {
			return;
		}
	};

	const printAll = async () => {
		setIsGettingReady(true);

		const bills = await getProfile({
			currentPage: 1,
			pageSize: total,
			printing: true
		})();

		await Promise.allSettled(
			bills.map(async (each) => {
				try {
					const bill = await axios
						.get(
							`mdanotice/${each.BillNumber}/${user?.mdaModuleCoy}`
						)
						.then((res) => res.data || { data: [] });

					setBillsToPrint((bills) => [...bills, bill]);
					setBillsToPrint((bills) => [...bills, "\n \n \n \n \n \n"]);
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `Bill ${each.BillNumber} failed to load: ${
								error.response.data?.msg ||
								error.response.data?.errors?.[0] ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response.message ||
								error.response.data.message
							}`
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `Bill ${each.BillNumber} failed to load: ${error.response?.data?.msg}`
						});
					}
				}
			})
		);

		setReadyToPrintAll(true);
		setIsGettingReady(false);
	};

	useEffect(() => {
		if (readyToPrintAll) {
			handleBulkPrinting();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyToPrintAll]);

	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{revokeModalStatus.showing && (
				<RegularModal
					showing={revokeModalStatus.showing}
					toggle={setRevokeModalStatus}
				>
					<p className={styles.revoke__warning}>
						Are you sure want to revoke assessment with Assessment
						Code: <b>{revokeModalStatus.data.AssessmentCode}</b>
					</p>
					<div className={styles.revoke__btns}>
						<button
							onClick={() =>
								setRevokeModalStatus({
									showing: false,
									ESBN: null
								})
							}
							className="secondary__btn"
						>
							Cancel
						</button>
						<button onClick={revoke}>
							{revokeModalStatus.revoking
								? "Revoking..."
								: "Revoke"}
						</button>
					</div>
				</RegularModal>
			)}
			<div className={styles.actions__div}>
				<button
					className="primary__btn"
					style={{
						padding: "0 25px"
					}}
					onClick={() => {
						history.push("/multi-company/prepare-bill", {
							taxpayers: true,
							fromIndividual: true,
							ssActive: "1"
						});
					}}
				>
					Prepare bill
				</button>

				<button
					className={[
						"secondary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
					// disabled={loading}
				>
					Download Report
				</button>

				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!OwnersESBN &&
								!startDate &&
								!endDate &&
								!ownersname &&
								// !OwnerEmail &&
								// !OwnersPhone &&
								!BillNumber &&
								!Inputter &&
								!PropertyId &&
								!houseNo &&
								!houseStreet &&
								!taxOffice &&
								!taxZone &&
								!taxLGA &&
								!processStatus &&
								!paymentStatus &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">ESBN</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={OwnersESBN}
									onChange={(e) =>
										setOwnersESBN(e.target.value)
									}
									placeholder="Eg. 00909986588"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg. AB2024155662EN"
								/>
							</div>
						</form>
						<p>By Owner Details</p>
						<form>
							<div>
								<label htmlFor="ownersname">Owner Name</label>
								<input
									name="OwnersName"
									id="ownersname"
									type="text"
									value={ownersname}
									onChange={(e) =>
										setownersname(e.target.value)
									}
									placeholder="Eg. Odogwu Okeke"
								/>
							</div>
							{/* <div>
								<label htmlFor="OwnerEmail">Owner Email</label>
								<input
									name="OwnerEmail"
									id="OwnerEmail"
									type="email"
									value={OwnerEmail}
									onChange={(e) =>
										setOwnerEmail(e.target.value)
									}
									placeholder="Eg. odogwu@okeke.com"
								/>
							</div>
							<div>
								<label htmlFor="OwnersPhone">Owner Phone</label>
								<input
									name="OwnersPhone"
									id="OwnersPhone"
									type="tel"
									value={OwnersPhone}
									onChange={(e) =>
										setOwnersPhone(e.target.value)
									}
									placeholder="Eg. 09333348848"
								/>
							</div> */}
							<div>
								<label htmlFor="PropertyId">Property ID</label>
								<input
									name="PropertyId"
									id="PropertyId"
									type="text"
									value={PropertyId}
									onChange={(e) =>
										setPropertyId(e.target.value)
									}
									placeholder="Eg. 4567"
								/>
							</div>
							<div>
								<label htmlFor="houseNo">House Number</label>
								<input
									name="houseNo"
									id="houseNo"
									type="text"
									value={houseNo}
									onChange={(e) => setHouseNo(e.target.value)}
									placeholder="Eg. No. 34 Odimegwu"
								/>
							</div>
							<div>
								<label htmlFor="houseStreet">
									House Street
								</label>
								<input
									name="houseStreet"
									id="houseStreet"
									type="text"
									value={houseStreet}
									onChange={(e) =>
										setHouseStreet(e.target.value)
									}
									placeholder="Eg. Uduma Street"
								/>
							</div>
							<div>
								<label htmlFor="taxLGA">Tax LGA</label>
								<select
									name="taxLGA"
									id="taxLGA"
									value={taxLGA}
									onChange={(e) => setTaxLGA(e.target.value)}
								>
									<option value="">--select tax LGA--</option>
									{allTaxLGAs?.map((LGA, i) => (
										<option key={i}>
											{LGA.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxZone">Tax Zone</label>
								<select
									name="taxZone"
									id="taxZone"
									value={taxZone}
									onChange={(e) => setTaxZone(e.target.value)}
								>
									<option value="">
										--select tax zone--
									</option>
									{allTaxZones?.map((zone, i) => (
										<option key={i}>
											{zone.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxOffice">Tax Office</label>
								<select
									name="taxOffice"
									id="taxOffice"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
								>
									<option value="">
										--select tax office--
									</option>
									{allTaxOffices?.map((office, i) => (
										<option key={i}>
											{office.Description}
										</option>
									))}
								</select>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="₦ 100, 000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="₦ 1, 000, 000"
								/>
							</div>
						</form>
						<p>By Status</p>
						<form>
							<div>
								<label htmlFor="processStatus">
									Process Status
								</label>
								<select
									name="processStatus"
									id="processStatus"
									value={processStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">
										--select process status--
									</option>
									<option>Approved</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
							<div>
								<label htmlFor="paymentStatus">
									Payment Status
								</label>
								<select
									name="paymentStatus"
									id="paymentStatus"
									value={paymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">
										--select payment status--
									</option>
									<option>Paid</option>
									<option>Partly Paid</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
						</form>
						<p>By Inputter</p>
						<form>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="email"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Eg. ada@example.com"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getProfile()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{data && (
				<p>
					<span style={{ color: "red" }}>*</span> Click each row to
					show amount paid from total bill amount.
				</p>
			)}
			<div className={homeStyle.printBtns}>
				<button
					className={[
						"secondary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={handleBulkPrinting}
				>
					Print Selected {billNumbersToPrint.length}
				</button>
				<button
					className={["primary__btn", homeStyle.printAll__Btn].join(
						" "
					)}
					style={{
						height: "47px"
					}}
					onClick={printAll}
					disabled={
						!OwnersESBN &&
						!BillNumber &&
						!ownersname &&
						!PropertyId &&
						!houseNo &&
						!houseStreet &&
						!taxLGA &&
						!taxZone &&
						!taxOffice &&
						!minAmount &&
						!maxAmount &&
						!processStatus &&
						!paymentStatus &&
						!Inputter &&
						!startDate &&
						!endDate
					}
				>
					{isGettingReady
						? "Getting Ready..."
						: `Print All ${
								(OwnersESBN ||
									BillNumber ||
									ownersname ||
									PropertyId ||
									houseNo ||
									houseStreet ||
									taxLGA ||
									taxZone ||
									taxOffice ||
									minAmount ||
									maxAmount ||
									processStatus ||
									paymentStatus ||
									Inputter ||
									startDate ||
									endDate) &&
								total
							}`}
				</button>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getProfile}
						onRowClick={(data) => {
							setAmountPaid("");
							setBillNoOfAmountPaidShowing(data.BillNumber);
							getAmountPaid(data.BillNumber);
						}}
						billNoOfAmountPaidShowing={billNoOfAmountPaidShowing}
						setBillNoOfAmountPaidShowing={
							setBillNoOfAmountPaidShowing
						}
						amountPaid={amountPaid}
						onEdit={(data) => {
							history.push(`/multi-company/billing/edit`, {
								coy: data.CoyId,
								userId: data.BillNumber
							});
						}}
						onView={(data) => {
							history.push(`/multi-company/billing/view`, {
								coy: data.CoyId,
								userId: data.BillNumber
							});
						}}
						onOthers={(data) =>
							setRevokeModalStatus((revokeStatus) => ({
								...revokeStatus,
								showing: true,
								data
							}))
						}
						printArray={billNumbersToPrint}
						onAddToPrint={(data) => {
							toggleAddToPrint(data.BillNumber);
						}}
						onRemoveFromPrint={(data) =>
							removeFromPrint(data.BillNumber)
						}
					/>
				)}
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</div>
	);
};

export default Profile;
