import React from "react";
import axios from "axios";
import Table from "../../../components/Table4";
import Alert from "../../../components/Alert";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import { useState, useEffect, createRef, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "../../../assets/arrowLeft.svg";
import { formatAmount } from "../../../utils";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";

const CorporateView = () => {
	let userId;
	const history = useHistory();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	// const [downloadFinish, setDownloadFinish] = useState("0");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [receiptData, setReceiptData] = useState(null);
	// const [pdfDownload, setPDFdownload] = useState(null);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const filterRef = createRef();
	const userIdRef = useRef(userId);
	// const userInfo = JSON.parse(localStorage.getItem("user_info"));

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("user_info")).account_type ===
			"corporate"
		) {
			userIdRef.current = JSON.parse(
				localStorage.getItem("user_info")
			).anssid;
		} else {
			userIdRef.current = location.state.CoyESBN;
		}
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			// let query = `page=${currentPage}&pageSize=20&`;
			// if (StaffName) {
			// 	query += `StaffName=${StaffName}&`;
			// }

			// dareports / tmspayedisplayDetails / 3472300125 / 2023 / January;

			try {
				const accessToken = localStorage.getItem("access_token");
				let { fiscalYear } = location.state;

				let { data, total } = await axios
					.get(
						`/dareports/tmspayedisplayDetails/${userIdRef.current}/${fiscalYear}/ALL`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });

				setData(data);
				setTotal(total);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.message ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.response?.data?.msg
					});
				}
			} finally {
				setFinished("1");
				// setLoading(false);
			}
		};

	const fetchReceipt = async () => {
		try {
			const res = await axios
				.get(`/gettmspaye/${location.state.BillNumber}/1`)
				.then((res) => res.data || {});
			setReceiptData(res);
		} catch (err) {
			console.error(err);
		} finally {
			setFinished("1");
		}
	};

	const fetchPDF = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.get(
				`/gettmspaye/${location.state.BillNumber}/2`,
				{
					responseType: "arraybuffer"
				}
			);

			const blob = new Blob([response.data], { type: "application/pdf" });

			// For Internet Explorer
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(blob, "downloaded-file.pdf");
			} else {
				// For other browsers
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "PAYE-Bill.pdf"; // specify the desired file name
				document.body.appendChild(link);
				link.click();

				// Clean up
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			console.error("Error downloading PDF:", error);
		}
	};

	useEffect(() => {
		fetchReceipt();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const printReceipt = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(receiptData);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		};
	};

	const redirectToCorrectPage = () => {
		const accounType = JSON.parse(
			localStorage.getItem("user_info")
		).account_type;

		if (accounType === "corporate") {
			history.push("/MyPaye-Record");
		} else {
			history.push("/PAYE/records");
		}
	};

	const headers = [
		["Staff Name ", "StaffName"],
		["Annual Income ", "AnnualPay", formatAmount],
		["Monthly Income ", "MthPay", formatAmount],
		["Total Relief", "TotRelief", formatAmount],
		["Taxable Income", "TaxableIncome", formatAmount],
		["Annual Tax Due ", "AnnualTaxDue", formatAmount],
		["Montly Tax Due", "MthTaxDue", formatAmount],
		["Development Levyyyyy", "DevLevy", formatAmount]
	];

	return (
		<main className={[styles2.wrapper, styles.main].join(" ")}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form>
				<div className={styles.review_header}>
					<div>
						<h2>PAYE Bill Number: {location.state.BillNumber}</h2>
						<h2>
							AMOUNT DUE: &#8358;{" "}
							{formatAmount(location.state.totAmtCharged)}
						</h2>
					</div>

					<button
						className={`${styles.review_header_btn} ${styles.customBackButton}`}
						// onClick={() => history.goBack(-1)}
						// onClick={() => history.push("/PAYE/records")}
						onClick={redirectToCorrectPage}
					>
						<img
							src={backArrow}
							alt="back arrow"
							className={styles.backArrow}
						/>{" "}
						<span className={styles.backText}>Go to Record</span>
					</button>
				</div>
				<div style={{ margin: "0px", marginTop: "30px" }}>
					<div className={styles1.table__div}>
						{loading && !data ? (
							<p style={{ textAlign: "center" }}>Loading...</p>
						) : (
							<Table
								headers={headers}
								data={data}
								full
								pageSize={20}
								total={total}
								onPageChanged={getAssessmentRecords}
							/>
						)}
					</div>
				</div>
			</form>
			{receiptData && (
				<>
					{/* <div className={styles.receiptBtn}>
						<button
							className={`primary__btn ${anssidStyles.create__btn}`}
							onClick={(e) => {
								printReceipt(e);
							}}
						>
							Print
						</button>

						{receiptData && (
							<button
								className={`primary__btn ${anssidStyles.create__btn}`}
								onClick={(e) => {
									fetchPDF(e);
								}}
							>
								Download PDF
							</button>
						)}
					</div> */}

					<div className={styles.receiptBtn}>
						<button
							className={styles.paye__btn}
							onClick={(e) => {
								printReceipt(e);
							}}
						>
							Print
						</button>

						{receiptData && (
							<button
								className={styles.paye__btn}
								onClick={(e) => {
									fetchPDF(e);
								}}
							>
								Download PDF
							</button>
						)}
					</div>

					<div
						dangerouslySetInnerHTML={{ __html: receiptData }}
						className={styles.doc__area}
					></div>
				</>
			)}
		</main>
	);
};

export default CorporateView;
