import React from "react";
import { Link } from "react-router-dom";

const Paymentpage = () => {
  return (
    <>
      <main>
        <center>
          <ul>
            <li>
              <Link to="/flutterwave">flutterwave payment</Link>
            </li>
          </ul>
        </center>
      </main>
    </>
  );
};

export default Paymentpage;
