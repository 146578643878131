// eslint-disable-next-line react-hooks/exhaustive-deps
import React from "react";
import axios from "axios";
import Table from "../../../components/Table2";
import styles from "./style.module.css";
import styles1 from "../../MDAs/style.module.css";
import { useState, useEffect, createRef } from "react";
import { useHistory } from "react-router-dom";

const Authorisation = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState("0");
	const [ESBN, setESBN] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);

	const [paymentStatus, setPaymentStatus] = useState("");
	// eslint-disable-next-line
	const [taxYear, setTaxYear] = useState("");

	const filterRef = createRef();

	useEffect(() => {
		getAssessmentRecords()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finished === "1"]);

	const getAssessmentRecords =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current?.classList.contains(styles1.no__display)) {
				filterRef.current?.classList.toggle(styles1.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (ESBN) {
				query += `ESBN=${ESBN}&`;
			}

			// if (startDate) {
			// 	query += `startdate=${startDate}&`;
			// }

			// if (endDate) {
			// 	query += `enddate=${endDate}&`;
			// }

			if (companyName) {
				query += `Name=${companyName}&`;
			}

			if (BillNumber) {
				query += `BillNumber=${BillNumber}&`;
			}

			if (paymentStatus) {
				query += `PaymentStatus=${paymentStatus}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let { data, total } = await axios
					.get(`/dareports/tmspayedisplay?${query}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					})
					.then((res) => res.data || { data: {} });
				// console.log("API Response:", data);

				if (!data) {
					// handle errors
					return null;
				}

				setData(data);
				setTotal(total);
			} catch (err) {
				console.error(err);
			} finally {
				setFinished("1");
				// setLoading(false);
			}
		};

	const clearFilters = () => {
		setESBN("");
		setCompanyName("");
		setBillNumber("");
		setPaymentStatus("");

		filterRef.current.classList.toggle(styles1.no__display);
		// setFiltersCleared(true);
	};

	// const downloadFormat = () => {
	// 	const element = document.createElement("a");
	// 	element.href = PAYESchedule;
	// 	element.download = "PAYE format";
	// 	element.click();
	// };

	const headers = [
		[`ESBN`, "CoyESBN"],
		["Company Name", "CoyName"],
		["Bill Number", "BillNumber"],
		["Fiscal Month", "fiscalMth"],
		["Fiscal Year ", "fiscalYear"],
		["Total Num of Staff", "totStaffNo"],
		["Total Montly charge", "totMthAmtBilled"],
		["Total Yearly charge", "totAmtCharged"],
		["Payment Status", "PaymentStatus"],
		["Process Status", "ProcessStatus"]
	];

	return (
		<section>
			<div className={`styles1.action__btns ${styles.header__btns}`}>
				<label
					className="secondary__btn"
					value="download"
					// onClick={() => downloadFormat()}
				>
					Uploaded PAYE Records
				</label>

				{/* <button
					className={`primary__btn ${anssidStyles.create__btn}`}
					onClick={() => history.push("/PAYE/PayeSchedule")}
				>
					Upload PAYE Schedule
				</button> */}
				<div>
					<div className={styles1.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles1.no__display
								);
							}}
							className={`${styles1.filter__button} ${styles.filter__button}`}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								styles1.filter__div,
								styles1.no__display
							].join(" ")}
						>
							<div className={styles1.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles1.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles1.clear__filter_fields}
								onClick={clearFilters}
								disabled={
									!ESBN &&
									!companyName &&
									!BillNumber &&
									!paymentStatus &&
									!taxYear
								}
							>
								Clear Filter fields
							</button>
							<p>By Code</p>
							<form onSubmit={(e) => e.preventDefault()}>
								<div>
									<label htmlFor="ESBN">ESBN</label>
									<input
										name="ESBN"
										id="ESBN"
										type="text"
										value={ESBN}
										onChange={(e) =>
											setESBN(e.target.value)
										}
										placeholder="Enter ESBN"
									/>
								</div>
							</form>
							<p>By Company Name</p>
							<form onSubmit={(e) => e.preventDefault()}>
								<div>
									<label htmlFor="companyName">
										Company Name
									</label>
									<input
										name="companyName"
										id="companyName"
										type="text"
										value={companyName}
										onChange={(e) =>
											setCompanyName(e.target.value)
										}
										placeholder="ABC Nig. Ltd "
									/>
								</div>
							</form>
							<p>By Bill Number</p>
							<form onSubmit={(e) => e.preventDefault()}>
								<div>
									<label htmlFor="BillNumber	">
										Company Bill Number
									</label>
									<input
										name="	"
										id="BillNumber"
										type="number"
										value={BillNumber}
										onChange={(e) =>
											setBillNumber(e.target.value)
										}
										placeholder="Eg.12345678"
									/>
								</div>
							</form>
							<p>By Company Status</p>
							<form onSubmit={(e) => e.preventDefault()}>
								<div>
									<label htmlFor="paymentStatus">
										Payment Status
									</label>
									<select
										name="paymentStatus"
										id="paymentStatus"
										value={paymentStatus}
										onChange={(e) =>
											setPaymentStatus(e.target.value)
										}
									>
										<option value="">Select</option>
										<option>Approved</option>
										<option>Revoked</option>
										<option>Pending</option>
										<option>Declined</option>
									</select>
								</div>
							</form>

							<button
								className={[
									styles1.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={getAssessmentRecords()}
							>
								Search
							</button>
						</div>
					</div>
				</div>
			</div>

			<div style={{ margin: "0px" }}>
				<div className={styles1.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table
							headers={headers}
							data={data}
							full
							pageSize={20}
							total={total}
							onPageChanged={getAssessmentRecords}
							// onEdit={(data) => {
							// 	history.push(`/PAYE/check`, {
							// 		...data,
							// 		ESBN: data.ESBN,
							// 		edit: true
							// 	});
							// }}
							// history.push(`/PAYE/Authorization`, {

							onView={(data) => {
								history.push(`/PAYE/Authorization`, {
									...data,
									ESBN: data.ESBN,
									view: true
								});
							}}

							// onApprove={console.log("approve")}
							// onOthers={(data) =>
							// 	setRevokeModalStatus((revokeStatus) => ({
							// 		...revokeStatus,
							// 		showing: true,
							// 		data
							// 	}))
							// }
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default Authorisation;
