import { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Alert from "../../../components/Alert";
import PAYESchedule from "../../../assets/paye_assessment_schedule_.xlsx";
import styles from "./style.module.css";
import styles1 from "../../../Identity-management-individual/style.module.css";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const CreatePAYEAssessment = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [ESBN, setESBN] = useState("");
	const [searching, setSearching] = useState(false);
	const [companyDetail, setCompanyDetail] = useState("");
	const [file, setFile] = useState(null);
	const [year, setYear] = useState("");
	const [showingUploadInput, setShowingUploadInput] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [showingDownloadProcessedBtn, setShowingDownloadProcessedBtn] =
		useState(false);
	const [processedPAYE, setProcessedPAYE] = useState(null);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();

	const getESBN = async (e) => {
		e.preventDefault();
		setSearching(true);
		try {
			const res = await axios
				.get(`/users/anssid?anssid=${ESBN}`)
				.then((res) => (res.data || {}).data);

			if (res) {
				if (res.accounttype !== "corporate") {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							"Organisation ESBN is only allowed for PAYE Upload!!!"
					});
				} else {
					setCompanyDetail(res);
					setAlert({
						...alert,
						showing: false // Hide any existing error messages
					});
				}
			} else {
				// If the response doesn't contain data, show an error message
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						"Company not found. Please check the ESBN and try again."
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		} finally {
			setSearching(false);
		}
	};

	const downloadFormat = () => {
		const element = document.createElement("a");
		element.href = PAYESchedule;
		element.download = "PAYE format";

		element.click();
	};

	const uploadFile = (e) => {
		e.preventDefault();
		setShowingDownloadProcessedBtn(false);

		if (file) {
			const reader = new FileReader();
			reader.onload = async (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);

				const inputter = userInfo.email;

				// Make an HTTP POST request to upload the Excel data as JSON
				try {
					const cleanupJSON = () => {
						let newJSON = json.slice(3);

						return newJSON.map((obj, i) => {
							if (!obj.__EMPTY_1) {
								throw new Error(
									`Entry ${i + 1} does not have a name`
								);
							} else if (!obj.__EMPTY_2) {
								throw new Error(
									`Entry ${
										i + 1
									} does not have a Total Income`
								);
							} else
								return {
									SN: obj.__EMPTY || i + 1,
									NAME: obj.__EMPTY_1,
									TOTALINCOME: obj.__EMPTY_2,
									PENSION: obj.__EMPTY_3 || 0,
									NHIS: obj.__EMPTY_4 || 0,
									NHF: obj.__EMPTY_5 || 0,
									LIFE: obj.__EMPTY_6 || 0
								};
						});
					};

					setUploading(true);
					let data = await axios
						.post(`tmspaye`, {
							coyname:
								JSON.parse(localStorage.getItem("user_info"))
									.account_type !== "corporate"
									? companyDetail.name
									: location.state.esbnName,
							esbn:
								JSON.parse(localStorage.getItem("user_info"))
									.account_type !== "corporate"
									? companyDetail.anssid
									: location.state.ESBN,
							year,
							// mth: month,
							coystaff: cleanupJSON(),
							inputter
						})
						.then((res) => res || {});

					if (data.status === 200) {
						setAlert({
							...alert,
							showing: true,
							type: "success",
							message:
								"Upload Successful! Go back to see Paye Details"
						});
					}

					setShowingDownloadProcessedBtn(true);
					setProcessedPAYE(data.data);
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.msg ||
								error.response.data?.message ||
								error.response.data?.errors?.[0] ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.message
						});
					}
				} finally {
					setUploading(false);
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const printPAYE = (e) => {
		e.preventDefault();

		const w = window.open();
		w.document.write(processedPAYE);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};

		w.onafterprint = () => {
			w.close();
		};
	};

	const location = useLocation();
	return (
		<main>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{userInfo.account_type !== "corporate" && (
				<form
					className={styles.searchForm}
					onSubmit={(e) => getESBN(e)}
				>
					<label className={styles1.input__label} htmlFor="ESBN">
						Company ESBN <span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="ESBN"
						type="text"
						id="ESBN"
						value={ESBN}
						onChange={(e) => setESBN(e.target.value)}
						className={styles.input}
						placeholder="eg. 9478372"
						required
					/>
					<button
						type="submit"
						className={[styles.search__btn, "primary__btn"].join(
							" "
						)}
						disabled={!ESBN}
					>
						{searching ? "Searching..." : "Search"}
					</button>
				</form>
			)}
			<>
				{companyDetail && (
					<div className={styles.details}>
						<div>
							<b>Company Name</b>
							<span>{companyDetail.name}</span>
						</div>
						<div>
							<b>Phone Number</b>
							<span>{companyDetail.phoneNumber}</span>
						</div>
						<div>
							<b>Email</b>
							<span>{companyDetail.email}</span>
						</div>
						<div>
							<b>Address</b>
							<span>{companyDetail.stateOfficeAddress}</span>
						</div>
					</div>
				)}
				{(companyDetail || userInfo.account_type === "corporate") && (
					<div className={styles.action__btns}>
						<button
							className={["secondary__btn"].join(" ")}
							value="download"
							onClick={() => downloadFormat()}
						>
							Download Xcel format
						</button>
						<button
							className={["primary__btn"].join(" ")}
							onClick={() => setShowingUploadInput(true)}
						>
							Upload PAYE Schedule
						</button>
						<button
							className={["secondary__btn"].join(" ")}
							onClick={() => history.goBack(-1)}
						>
							Go back
						</button>
						{/* <button
							className={`${styles2.review_header_btn} ${styles.customBackButton}`}
							onClick={() => history.goBack(-1)}
						>
							<img
								src={backArrow}
								alt="back arrow"
								className={styles.backArrow}
							/>
							<span className={styles.backText}>Back</span>
						</button> */}
					</div>
				)}
			</>
			{showingUploadInput && (
				<form
					className={styles.payeForm}
					onSubmit={(e) => uploadFile(e)}
				>
					{/* <div>
						<label className={styles1.input__label} htmlFor="month">
							Month
						</label>
						<select
							id="month"
							value={month}
							onChange={(e) => setMonth(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>January</option>
							<option>February</option>
							<option>March</option>
							<option>April</option>
							<option>May</option>
							<option>June</option>
							<option>July</option>
							<option>August</option>
							<option>September</option>
							<option>October</option>
							<option>November</option>
							<option>December</option>
						</select>
					</div> */}
					<div>
						<label className={styles1.input__label} htmlFor="year">
							Year
						</label>
						<select
							id="year"
							value={year}
							onChange={(e) => setYear(e.target.value)}
							required
						>
							<option value="">Select</option>
							<option>2018</option>
							{new Array(new Date().getFullYear() - 2018)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2018 + i + 1}</option>
								))}
						</select>
					</div>
					<div>
						<label className={styles1.input__label} htmlFor="file">
							Company's excel Schedule{" "}
							<span style={{ color: "red" }}>
								(must be excel)
							</span>
						</label>
						<input
							name="file"
							type="file"
							id="file"
							onChange={(e) => {
								e.preventDefault();
								setFile(e.target.files[0]);
							}}
							className={styles.fileInput}
							placeholder="eg. example.xlsx"
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							required
						/>
						<button
							type="submit"
							className={[
								styles.search__btn,
								"primary__btn"
							].join(" ")}
							disabled={!file || !year}
							// disabled={!file || !month || !year}
						>
							{uploading ? "Uploading..." : "Upload"}
						</button>
						{showingDownloadProcessedBtn && (
							<button
								type="button"
								className={[
									styles.search__btn,
									"secondary__btn"
								].join(" ")}
								onClick={(e) => printPAYE(e)}
							>
								Download as PDF
							</button>
						)}
					</div>
				</form>
			)}
		</main>
	);
};

export default CreatePAYEAssessment;
