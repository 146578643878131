/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import style from "./style.module.css";
import axios from "axios";
import Alert from "../../../components/Alert";
import { useHistory } from "react-router";
import FlutterWaveForPayment from "../../../assets/Home/flutterwaveLogo.webp";
import { Mdas } from "../../../payment/components/mdas";
import constants from "../../../constants";
import arrowLeft from "../../../assets/PAYEarrow.svg";

const coylogo = process.env.REACT_APP_COYNAME;

// let imgpath = "../assets/Home/" + process.env.REACT_APP_COYLOGO;
axios.defaults.testURL = constants.BASE_URL;

const Flutterwave = ({ data, setModalToShow }) => {
	const user = JSON.parse(localStorage.getItem("user_info"));

	const [BillNumber, setBillNumber] = useState("");
	const [ownersesbn, setownersesbn] = useState("");
	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [getrev, setRevhead] = useState([]);
	const [filterPayeeAlone, setFilterPayeeAlone] = useState([]);
	const [revenue, setRevenue] = useState("");
	const [mda, setMdas] = useState("");
	const [ESBNDetails, setESBNDetails] = useState(null);
	const [showESBN, toggleShowESBN] = useState("esbn");
	const [showAlert, setShowAlert] = useState(true);

	const history = useHistory();

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const RevenueHead2 = async () => {
		try {
			const response = await axios.get(
				`/settings/mdalinkedrevenue/${mda}`
			);
			const data = response.data?.data || [];

			const filterPayeeAlone = data.filter((item) => {
				return (
					item.Description === "Pay as You Earn (PAYE) - (Arrears)" ||
					item.Description === "Pay As You Earn (PAYE) - Companies" ||
					item.Description === "Pay As You Earn (PAYE) - Federal" ||
					item.Description ===
						"Pay As You Earn (PAYE) - Local Government" ||
					item.Description ===
						"Pay As You Earn (PAYE) - State (Adjustment Voucher)"
				);
			});

			setFilterPayeeAlone(filterPayeeAlone);
		} catch (error) {
			console.error("Error fetching revenue data", error);
		}
	};

	useEffect(() => {
		RevenueHead2();
	}, [mda]);

	function paymentCallback(response) {
		const requestData = {
			paymentgateway: "FLUTTERWAVE",
			paymentoption: showESBN,
			paygatetranid: "FLUTTERWAVE_id",
			billnumber: BillNumber,
			billername: ownersesbn ? ownersesbn : name,
			billerphoneno: phone ? phone : ESBNDetails?.phone,
			billeremail: email ? email : ESBNDetails?.email,
			amount: amount,
			mda: mda,
			revenue: revenue,
			payload: [
				{
					tx_ref: randoReference(),
					amount: Number(amount),
					currency: "NGN",
					payment_options: "card, banktransfer, ussd",
					meta: {
						source: "docs-inline-test",
						consumer_mac: "92a3-912ba-1192a"
					},
					customer: {
						email: email ? email : ESBNDetails?.email,
						phone_number: phone,
						name: name
					},
					customizations: {
						title: "ENUGU STATE INTERNAL REVENUE SERVICE",
						description: "Test Payment",
						logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png"
					}
				}
			]
		};

		// API endpoint
		const apiEndpoint = `${constants.BASE_URL}/taxpaid`;

		// Use Axios to send the POST request
		axios
			.post(apiEndpoint, requestData, {
				headers: {
					"Content-Type": "application/json"
					// Add any additional headers as needed
				}
			})
			.then((response) => {
				console.log("Data sent successfully");
				// Handle success if needed
			})
			.catch((error) => {
				console.error("Failed to send data to the API:", error);
				// Handle errors if needed
			});
	}
	const handleButton = async () => {
		try {
			await paymentCallback();
		} catch (error) {
			// Handle errors from Function 1
			console.error("Error in Function 1:", error);
		}
		// Execute Function 2
		await makePayment();
	};

	function randoReference() {
		var length = 10;
		var chars =
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var result = "";
		for (var i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.surname}`);
			} else {
				setName(res.name);
			}

			setESBNDetails(res);
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => {
					if (res?.data?.data[0]?.GrandTotal) {
						setAmount(res.data.data[0].GrandTotal);
					}
				})
				.catch((error) => {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.errors?.[0] ||
								error.response.data?.msg
						});
					}
				});
		};
		if (BillNumber?.length >= 18) getBillAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BillNumber]);

	useEffect(() => {
		// Load the FlutterwaveCheckout library
		const script = document.createElement("script");
		script.src = "https://checkout.flutterwave.com/v3.js";
		script.async = true;
		document.body.appendChild(script);

		script.onload = () => console.log("flutterwave loaded");
	}, []);

	//publickey
	const publicKey = process.env.REACT_APP_FLUTTERWAVE;

	function makePayment() {
		window.FlutterwaveCheckout({
			public_key: publicKey,
			tx_ref: randoReference(),
			amount: Number(amount),
			currency: "NGN",
			payment_options: "card, banktransfer, ussd",
			meta: {
				source: "docs-inline-test",
				consumer_mac: "92a3-912ba-1192a"
			},
			customer: {
				email: email ? email : ESBNDetails?.email,
				phone_number: phone,
				name: name
			},
			customizations: {
				title: "ENUGU STATE INTERNAL REVENUE SERVICE",
				description: "Test Payment",
				logo: "https://checkout.flutterwave.com/assets/img/rave-logo.png"
			},
			callback: function (payment) {
				paymentCallback(payment);
			}
		});
	}

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}

			<main className={style.main}>
				<div className={styles.paymentBody}>
					<div className={styles.paymentIcon}>
						<div
							className={styles.goBack}
							onClick={() => setModalToShow("gatewayList")}
						>
							<img
								src={arrowLeft}
								alt="arrowLeftIcon"
								style={{
									width: "30px"
								}}
							/>
						</div>

						<img
							src={FlutterWaveForPayment}
							alt="interswitch payment"
						/>

						<div></div>
					</div>
					<hr className={style.divider} />
					{showESBN === "esbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="ownersesbn"
										>
											ESBN{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setownersesbn(e.target.value)
											}
											id="ownersesbn"
											placeholder="enter your ESBN"
											required
											value={user?.anssid}
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="BillNumber"
										>
											Bill Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setBillNumber(e.target.value)
											}
											id="BillNumber"
											placeholder="Enter bill number"
											required
											value={data?.BillNumber}
										/>
									</div>
								</div>
								<hr className={style.divider} />

								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={user?.fullname}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="name"
											required
											disabled
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={data?.totAmtCharged}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />
								<div className={style.payments__double__inputs}>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option value="">
												{filterPayeeAlone
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{/* {getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))} */}
											{filterPayeeAlone.map(
												(payeeList) => (
													<option
														value={payeeList.code}
													>
														{payeeList.Description}
													</option>
												)
											)}
										</select>
									</div>
								</div>
								<hr className={style.divider} />

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{mda && revenue && (
										<button
											onClick={handleButton}
											className={[style.btn]}
										>
											Pay now
										</button>
									)}
								</div>
							</div>
						</>
					)}
					{showESBN === "noEsbn" && (
						<>
							<div className={styles.wrapper}>
								<div className={style.payments__double__inputs}>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="name"
											placeholder="Enter Name"
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />

								<div
									className={[
										style.payments__double__inputs
									].join(" ")}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="email"
										>
											Email{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											id="name"
											placeholder="Enter Email"
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="phone"
										>
											Phone Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={phone}
											onChange={(e) =>
												setPhone(e.target.value)
											}
											id="amount"
											placeholder="Enter phone number"
										/>
									</div>
								</div>

								<br />
								<div className={style.payments__double__inputs}>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option>
												{getrev
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{name &&
										amount &&
										email &&
										phone &&
										revenue && (
											<button
												onClick={handleButton}
												className={[style.btn]}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
				</div>
			</main>
		</>
	);
};

export default Flutterwave;
