/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import CreateTaxPayer from "./create";
import styles from "../MDAs/style.module.css";
import anssidStyles from "./style.module.css";

const user = JSON.parse(localStorage.getItem("user_info"));

const Profile = () => {
	const history = useHistory();

	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [LGA, setLGA] = useState("");
	const [PropertyId, setPropertyId] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [Inputter, setInputter] = useState("");
	const [PropertyAddr, setPropertyAddr] = useState("");
	const [PropertyCity, setPropertyCity] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const filterRef = createRef();

	const headers = [
		// ["Profile ID", "ProfileId"],
		["PID No", "PropertyId"],
		["Property City", "PropertyCity"],
		["Date of Creation", "createdAt"],
		["PID Description", "PropertyAddr"],
		["PID City", "PropertyCity"],
		["Creator", "Inputter"]
	];

	const getProfile =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef.current.classList.contains(styles.no__display)) {
				filterRef.current.classList.toggle(styles.no__display);
			}

			let query = `page=${currentPage}&pageSize=20&`;
			if (PropertyId) {
				query += `PropertyId=${PropertyId}&`;
			}

			if (startDate) {
				query += `start=${startDate}&`;
			}

			if (endDate) {
				query += `end=${endDate}&`;
			}

			if (LGA) {
				query += `lgaa=${LGA}&`;
			}

			if (Inputter) {
				query += `Inputter=${Inputter}&`;
			}

			if (PropertyCity) {
				query += `PropertyCity=${PropertyCity}&`;
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				console.log({ query });
				let data = await axios
					.get(
						`mdareports/mdaprofiledisplay/${user?.mdaModuleCoy}?${query}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => (res.data || { data: {} }).data);

				if (!data) {
					// handle errors
					return null;
				}

				data = data.map((d) => {
					return {
						...d,
						owneresbn: d.ProfileId,
						OwnersName: PropertyAddr,
						corporateName: /corporate/i.test(d.accountType)
							? d?.CorporateCompanyInfo?.name
							: undefined,
						owneremail: d.PropertyCity,
						OwnershipType: d.OwnershipType
						// maritalstatus: d?.UserFamilyInfo?.maritalstatus
					};
				});

				setData(data);
				setTotal(total);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

	const clearFilters = () => {
		setPropertyId("");
		setStartDate("");
		setEndDate("");
		setPropertyAddr("");
		setPropertyCity("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getProfile();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared]);

	useEffect(() => {
		getProfile()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.MDA__page}>
			<div className={styles.actions__div}>
				<button
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push("/create-multicoy", {
							taxpayers: true,
							fromIndividual: true,
							ssActive: "1"
						});
					}}
				>
					Create Profile
				</button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!LGA &&
								!PropertyId &&
								!startDate &&
								!endDate &&
								!PropertyCity
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="lgaa">LGA</label>
								<input
									name="lgaa"
									id="lgaa"
									type="text"
									value={LGA}
									onChange={(e) => setLGA(e.target.value)}
									placeholder="LGA"
								/>
							</div>
						</form>
						<p>By Property Details</p>
						<form>
							<div>
								<label htmlFor="PropertyId">Property Id</label>
								<input
									name="PropertyId"
									id="PropertyId"
									type="text"
									value={PropertyId}
									onChange={(e) =>
										setPropertyId(e.target.value)
									}
									placeholder="Eg. 0967"
								/>
							</div>
							<div>
								<label htmlFor="PropertyCity">
									Property City
								</label>
								<input
									name="PropertyCity"
									id="PropertyCity"
									type="email"
									value={PropertyCity}
									onChange={(e) =>
										setPropertyCity(e.target.value)
									}
									placeholder="Eg. Enugu North"
								/>
							</div>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="text"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="example@testEmail.com"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getProfile()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getProfile}
						// onEdit={(data) => {
						// 	history.push(
						// 		`/multi-company/profile/edit`,
						// 		{
						// 			coy: data.CoyId,
						// 			userId: user?.id
						// 		}
						// 	);
						// }}
						// onView={(data) => {
						// 	history.push(
						// 		`/multi-company/profile/view`,
						// 		{
						// 			coy: data.CoyId,
						// 			userId: user?.id
						// 		}
						// 	);
						// }}
					/>
				)}
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</div>
	);
};

export default Profile;
